import React, {useEffect} from 'react';
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";

import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment/moment";
import {Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import Calendar from "./calendar";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Translation} from "react-i18next";


const data = [
    {
        date: Date.parse("2019-01-01T09:00:00"),
        tariff0: 1,
        tariff1: 0,
        tariff2: 0,
    },
    {
        date: Date.parse("2019-01-01T10:00:00"),
        tariff0: 6,
        tariff1: 1,
        tariff2: 2,
    },
    {
        date: Date.parse("2019-01-01T11:00:00"),
        tariff0: 7,
        tariff1: 1,
        tariff2: 2,
    },
    {
        date: Date.parse("2019-01-01T12:00:00"),
        tariff0: 10,
        tariff1: 4,
        tariff2: 5,
    },
    {
        date: Date.parse("2019-01-01T13:00:00"),
        tariff0: 7,
        tariff1: 3,
        tariff2: 5,
    },

    {
        date: Date.parse("2019-01-01T14:00:00"),
        tariff0: 11,
        tariff1: 2,
        tariff2: 0,
    },
    {
        date: Date.parse("2019-01-01T15:00:00"),
        tariff0: 11,
        tariff1: 2,
        tariff2: 2,
    },
    {
        date: Date.parse("2019-01-01T16:00:00"),
        tariff0: 14,
        tariff1: 3,
        tariff2: 5,
    },
    {
        date: Date.parse("2019-01-01T17:00:00"),
        tariff0: 14,
        tariff1: 3,
        tariff2: 8,
    },

];


export default function Index() {
    const navigate = useNavigate();
    useEffect(() => {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
        ];
    }, [])


    return (
        <div>
            <Row className="row">
                <div className="col-lg-3 col-6">
                    <div className="small-box bg-info" onClick={() => navigate("/kassa")}>
                        <div className="inner"><h3>14 чел</h3><p>Загрузка</p></div>
                        <div className="icon">
                            <FontAwesomeIcon icon={solid("users")} className="pl-2"/>
                        </div>
                        <a href="#" className="small-box-footer" onClick={() => navigate("/kassa")}>
                            Подробнее
                            <FontAwesomeIcon icon={solid("arrow-circle-right")} className="pl-2"/>
                        </a>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                        <div className="inner">
                            <h3> - </h3>
                            <p>Брони</p>
                        </div>
                        <div className="icon">
                            {/* <FontAwesomeIcon icon={solid("list-check")} className="pl-2"/>*/}
                            <FontAwesomeIcon icon={solid("shield-halved")} className="pl-2"/>
                        </div>
                        <a href="#" className="small-box-footer" onClick={() => toast.dark("Доступно по подписке")}>
                            Без подробностей
                            <FontAwesomeIcon icon={solid("arrow-circle-right")} className="pl-2"/>
                        </a>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                        <div className="inner">
                            <h3>-</h3>
                            <p>Ивентов</p>
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon={solid("face-dizzy")} className="pl-2"/>
                        </div>
                        <a href="#" className="small-box-footer" onClick={() => navigate("/table-games/events")}>
                            Перейти к ивентам
                            <FontAwesomeIcon icon={solid("arrow-circle-right")} className="pl-2"/>
                        </a>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-dark">
                        <div className="inner">
                            <Row>
                               {/* <Col>
                                    <img src="/react/akim2.jpeg" height="93px" className="rounded"/>
                                </Col>*/}
                                <Col>
                                    <h5>Нет админа</h5>
                                    <p>Смена 2/3</p>
                                    <br/>
                                </Col>
                            </Row>
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon={solid("face-dizzy")} className="pl-2"/>
                        </div>
                        <a href="#" className="small-box-footer" onClick={() => toast.dark("В чате узнай")}>
                            Календарь смен
                            <FontAwesomeIcon icon={solid("arrow-circle-right")} className="pl-2"/>
                        </a>
                    </div>
                </div>

            </Row>
            <Row>
                <Col md={6}>
                    <Card>
                        <Card.Header><Card.Title>Загрузка по часам</Card.Title></Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width={'100%'} height={300}>
                                <BarChart data={data} margin={{top: 5, right: 5, left: 5, bottom: 5}}>
                                    <CartesianGrid strokeDasharray="3 3" horizontal={true}/>
                                    {/* <XAxis dataKey="name" />*/}
                                    <XAxis dataKey="date"
                                           tickFormatter={timeStr => moment(timeStr).format('HH:mm')}/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Legend/>
                                    <Bar dataKey="tariff0" name="Посетители" stackId="a" fill="#17a2b8"/>
                                    <Bar dataKey="tariff1" name="Студенты" stackId="a" fill="#ffc107"/>
                                    <Bar dataKey="tariff2" name="Постоянники" stackId="a" fill="#28a745"/>
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Календарь броней</Card.Title>
                            <a href="https://calendar.google.com/calendar/u/0/r" target="_blank">
                                <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} className="pl-2"/>
                            </a>
                        </Card.Header>
                        <Card.Body style={{padding: 0}}>
                            <Calendar/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/*        <Card>
                        <Card.Header>
                            <Card.Title>Гугл Каледарь</Card.Title>
                        </Card.Header>
                        <Card.Body>

                        </Card.Body>
                    </Card>*/}


        </div>
    );
}