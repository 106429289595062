import BaseIndexPage from "../BaseIndexPage";
import {BaseModelInfo} from "../../types/BaseModelInfo";
import ErrorWidget from "../../components/ErrorWidget";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import TableIndex from "../../TableIndex/TableIndex";
import MainLoader from "../../../ui/loaders/MainLoader";
import React from "react";

type Props = {
    modelInfo: BaseModelInfo;
    onCreateClick: () => void;
    onRowClick: (id:number) => void;

}

export class SpaCrudIndex extends BaseIndexPage {
    constructor(props: Props) {
        super(props);
        super.model = props.modelInfo;
        super.defaultShowAttrs = props.modelInfo.defaultShowAttrs;
        super.relationTree = props.modelInfo.relationTree
    }

    render() {
        if (this.state.hasError) {
            return <ErrorWidget/>;
        }
        return (
            <Card className="card-outline card-success">
                <Card.Header>
                    <Card.Title>{this.model.info.label}</Card.Title>
                  {/*  <div className="card-tools">
                        <Link className="btn-outline-success btn btn-sm" to={'create'}>Create</Link>
                    </div>*/}
                </Card.Header>

                <Card.Body className="p-0">
                    {this.state.isLoadedFirstPage ? (
                        <>
                            <TableIndex
                                model={this.model}
                                data={this.state.data.data}
                                enabledAttributes={this.defaultShowAttrs}
                                actions={{update: {title: "update"}}}
                                pagination={{data: this.state.data.pagination, callback: this.actionPagination}}
                                sortBy={this.state.sortBy}
                                onSortChange={this.onSortChange}
                                searchQuery={this.state.searchArray}
                                onSearch={this.onSearch}
                                onCreateClick={this.props.onCreateClick}
                                onRowClick={this.props.onRowClick}
                                onRelationTreeChange={this.onTreeChange}
                            />
                        </>
                    ) : (
                        <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>
                    )}
                </Card.Body>
            </Card>
        );
    }
}