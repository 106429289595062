/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import UserCheckpointsForm from "./_form";
import {UserCheckpointsModel} from "./_model";


class UserCheckpointsCreate extends BaseCreatePage {
    //public model = UserCheckpointsModel;
    //public FormComponent = UserCheckpointsForm;

    constructor(props: any) {
        super(props);
        super.model = UserCheckpointsModel;
        super.FormComponent = UserCheckpointsForm;
        super.relationTree = UserCheckpointsModel.relationTree
    }
}

export default UserCheckpointsCreate;