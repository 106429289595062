/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import TelegrambotModulesForm from "./_form";
import {TelegrambotModulesModel} from "./_model";

class TelegrambotModulesUpdate extends BaseUpdatePage {
    //public model = TelegrambotModulesModel;
    //public FormComponent = TelegrambotModulesForm;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotModulesModel;
        super.FormComponent = TelegrambotModulesForm;
        super.relationTree = TelegrambotModulesModel.relationTree
    }
}

export default withTranslation()(withRouter(TelegrambotModulesUpdate));
