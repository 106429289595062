/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import TableGamesForm from "./_form";
import {TableGamesModel} from "./_model";


class TableGamesCreate extends BaseCreatePage {
    //public model = TableGamesModel;
    //public FormComponent = TableGamesForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesModel;
        super.FormComponent = TableGamesForm;
        super.relationTree = TableGamesModel.relationTree
    }
}

export default TableGamesCreate;