/**
 * Generated by ReactYii 3.0.27 at 07.05.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import TagsForm from "./_form";
import {TagsModel} from "./_model";

class TagsUpdate extends BaseUpdatePage {
    //public model = TagsModel;
    //public FormComponent = TagsForm;

    constructor(props: any) {
        super(props);
        super.model = TagsModel;
        super.FormComponent = TagsForm;
        super.relationTree = TagsModel.relationTree
    }
}

export default withTranslation()(withRouter(TagsUpdate));
