/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import TableGamesTypesForm from "./_form";
import {TableGamesTypesModel} from "./_model";

class TableGamesTypesUpdate extends BaseUpdatePage {
    //public model = TableGamesTypesModel;
    //public FormComponent = TableGamesTypesForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesTypesModel;
        super.FormComponent = TableGamesTypesForm;
        super.relationTree = TableGamesTypesModel.relationTree
    }
}

export default withTranslation()(withRouter(TableGamesTypesUpdate));
