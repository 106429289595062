/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import EventCalendarsForm from "./_form";
import {EventCalendarsModel} from "./_model";

class EventCalendarsUpdate extends BaseUpdatePage {
    //public model = EventCalendarsModel;
    //public FormComponent = EventCalendarsForm;

    constructor(props: any) {
        super(props);
        super.model = EventCalendarsModel;
        super.FormComponent = EventCalendarsForm;
        super.relationTree = EventCalendarsModel.relationTree
    }
}

export default withTranslation()(withRouter(EventCalendarsUpdate));
