import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import CamToolbar from "./toolbar";
import {CamViewState} from "./types";
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";


export default function CamView(props: any) {
    let {id} = useParams();

    //const base = 'http://195.91.199.99:9786';
    const base = 'https://zdbot.zdver.com:4445';
    /*
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    */

    useEffect(() => {
        breadcrumbsStore.items = [
            {name: 'Home', link: '/'},
            {name: "Камеры", link: '/cam'},
            {name: id, link: ''}
        ];


    }, []);

    const [values, setValues] = useState<any>([]);
    const [camViewState, setCamViewState] = useState(new CamViewState())

    /*    const MakeARequest = async () => {
            const response = await digestAuth.request({
                headers: { Accept: "application/json" },
                method: "GET",
                url: base + "/cameras",
            }).then(r  =>{
                setValues(r.data)
                console.log(r.data)
            })
        }*/

    return (
        <Container fluid={true}>
            <CamToolbar value={camViewState}/>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <img src={
                                base + "/cameras/" + id + "/video" +
                                "?quality=40" +
                                "&fps=10"
                                //"&resolution=648x480"
                            } className="img-fluid"/>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

