/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField, SwitchField, WysiwygEditor} from "crud";
import BaseForm from "crud/base/BaseForm";
import RelationOneDropDown from "crud/FormInputs/RelationOneDropDown";
import {EventsModel} from "./_model";
import {Row} from "react-bootstrap";
import Places from "./places.json";
import DropDownList from "../../../crud/FormInputs/DropDownList";
import DndImage from "../../../crud/FormInputs/files/DndImage";


class EventsForm extends BaseForm {
    //public model = EventsModel;

    constructor(props: any) {
        super(props);
        super.model = EventsModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                event_type_id: '',
                url: '',
                name: '',
                description: '',
                fee: '',
                duration: '',
                image_name: '',
                place: '',
                obsolete: '',
                is_enabled: '',
                vk: '',
                facebook: '',
                day_of_week: '',
                repeatable: '',
                from_dt: '',
                to_dt: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>


                    {/*<InputField name={'event_type_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.event_type_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['event_type_id']}/>*/}

                    <InputField name={'name'}
                                model={this.model.info.name}
                                label={this.model.attrs.name.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['name']}/>

                    <InputField name={'url'}
                                model={this.model.info.name}
                                label={this.model.attrs.url.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['url']}/>


                    <RelationOneDropDown name={'eventTypes_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.eventType?.label}
                                         relationUrl={this.model.attrs.eventType?.params?.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "event_type_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['event_type_id']}/>

                    <DndImage name={'image_name'}
                                modelId={this.state.id}
                                model={this.model.info.name}
                                label={this.model.attrs.image_name.label}
                                attr={this.model.attrs.image_name}
                                onChange={this.handleInputChange}
                                value={this.state.form['image_name']}/>

                    <InputField name={'image_name'}
                                model={this.model.info.name}
                                label={this.model.attrs.image_name.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['image_name']}/>

                    <WysiwygEditor name={'description'}
                                model={this.model.info.name}
                                label={this.model.attrs.description.label}
                                //onChange={this.handleInputChange}
                                 onChange={(value: any) => this.handleInputChange({
                                       name: "description",
                                       value: value
                                   })}
                                value={this.state.form['description']}/>

                    <InputField name={'fee'}
                                model={this.model.info.name}
                                label={this.model.attrs.fee.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['fee']}/>

                    <InputField name={'duration'}
                                model={this.model.info.name}
                                label={this.model.attrs.duration?.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['duration']}/>

                    <InputField name={'place'}
                                model={this.model.info.name}
                                label={this.model.attrs.place.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['place']}/>
                    <DropDownList name={'place'}
                                      model={this.model.info.name}
                                      label={this.model.attrs.place.label}
                                      onChange={this.handleInputChange}
                                      value={this.state.form['place']}
                                      options={
                                          Object.entries(Places).map(([value, label]) => ({ value, label }))
                                      }
                    />

                    <SwitchField name={'is_enabled'}
                                model={this.model.info.name}
                                label={this.model.attrs.is_enabled.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['is_enabled']}/>

                  {/*  <InputField name={'vk'}
                                model={this.model.info.name}
                                label={this.model.attrs.vk.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['vk']}/>

                    <InputField name={'facebook'}
                                model={this.model.info.name}
                                label={this.model.attrs.facebook.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['facebook']}/>*/}

                    <Row className="row-cols-2">
                        <InputField name="vk"
                                    model={this.model.info.name}
                                    label={<b>vk.com://</b>}
                                    onChange={this.handleInputChange} value={this.state.form['vk']} class="p-2"/>

                        <InputField name={'facebook'}
                                    model={this.model.info.name}
                                    label={'Fb Link'}
                                    onChange={this.handleInputChange} value={this.state.form['facebook']} class="p-2"/>
                    </Row>

                {/*    <InputField name={'day_of_week'}
                                model={this.model.info.name}
                                label={this.model.attrs.day_of_week.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['day_of_week']}/>

                    <InputField name={'repeatable'}
                                model={this.model.info.name}
                                label={this.model.attrs.repeatable.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['repeatable']}/>

                    <InputField name={'from_dt'}
                                model={this.model.info.name}
                                label={this.model.attrs.from_dt.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['from_dt']}/>

                    <InputField name={'to_dt'}
                                model={this.model.info.name}
                                label={this.model.attrs.to_dt.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['to_dt']}/>
*/}
                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default EventsForm;
