/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {TelegrambotModulesModel} from "./_model";
import React from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import ConfigsCard from "./configsCard";
import CommandsCard from "./commandsCard";
import {Link, Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {DataTable} from "../../../crud";
import {BaseDeleteModal} from "../../../crud/dialogs/BaseDeleteModal";

class TelegrambotModulesView extends BaseViewPage {
    //public model = TelegrambotModulesModel;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotModulesModel;
        super.relationTree = TelegrambotModulesModel.relationTree
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath}/>;
        }
        return <>
            <BaseDeleteModal show={this.state.modal} onDelete={this.deleteModel} onClose={this.closeModal}/>

            <Card className="card-outline card-success">
                <Card.Header>
                    <Row className="justify-content-between">
                        <Col><h1>{this.state.data?.name ?? "#" + this.state.data?.id}</h1></Col>
                        <Col>
                            <div className="float-right">
                                <Link className="btn btn-primary"
                                      to={this.model.info.viewPath + '/update/' + this.state.data.id}>
                                    <Translation>{(t: any) => t('crud.update')}</Translation>
                                </Link>
                                <Button variant="danger" className="ml-2"
                                        onClick={() => this.openModal(this.state.data.id)}>
                                    <Translation>{(t: any) => t('crud.delete')}</Translation>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        data={this.state.data}
                        model={this.model}
                        disabledFields={this.disabledAttr}
                    />
                </Card.Body>
            </Card>

            <Row className="row-cols-2">
                <Col>

                    <CommandsCard moduleId={this.state.data.id}
                                  moduleCommands={this.state.data?.telegrambotModuleCommands}
                                  callback={() => this.componentDidMount()}/>
                </Col>
                <Col>

                    <ConfigsCard moduleId={this.state.data.id}
                                 moduleConfigs={this.state.data?.telegrambotModuleConfigs}
                                 callback={() => this.componentDidMount()}/>
                </Col>
            </Row>
        </>
    }
}

export default withRouter(TelegrambotModulesView);