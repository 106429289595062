/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import {WikiPagesModel} from "./_model";
import TagsDropDown from "../../../crud/FormInputs/custom/TagsDropDown";
import RelationOneDropDown from "../../../crud/FormInputs/RelationOneDropDown";
import MarkdownEditor from "@uiw/react-markdown-editor";

class WikiPagesForm extends BaseForm {
    //public model = WikiPagesModel;

    constructor(props: any) {
        super(props);
        super.model = WikiPagesModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                wiki_category_id: '',
                title: '',
                text: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                  {/*  <InputField name={'wiki_category_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.wiki_category_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.wiki_category_id}/>*/}
                    <RelationOneDropDown name={'wiki_category_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.wikiCategory?.label}
                                         relationUrl={this.model.attrs.wikiCategory.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "wiki_category_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['wiki_category_id']}/>

                    <InputField name={'title'}
                                model={this.model.info.name}
                                label={this.model.attrs.title.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.title}/>

                    <TagsDropDown name={"tags"}
                                  model={this.model.info.name}
                                  label={"теги"}
                                  onChange={(val: any) => this.handleInputChange({
                                      name: "wikiTags_id",
                                      value: val.id
                                  })}
                                  value={this.state.form['wikiTags_id']} />

                   {/* <InputField name={'text'}
                                model={this.model.info.name}
                                label={this.model.attrs.text.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.text}/>*/}
                    <MarkdownEditor
                        value={this.state.form.text}
                        onChange={(val: any) => this.handleInputChange({
                            name: "text",
                            value: val
                        })}
                    />



                    {/*                    <RelationOneDropDown name={'wikiTags_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.wikiTags_id?.label}
                                         relationUrl={this.model.attrs.wikiTags.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "wikiTags_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['wikiTags_id']}/>

                    <RelationOneDropDown name={'wikiCategory_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.wikiCategory_id?.label}
                                         relationUrl={this.model.attrs.wikiCategory.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "wikiCategory_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['wikiCategory_id']}/>*/}

                    {/*      <RelationOneDropDown name={'wikiToTags_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.wikiToTags_id?.label}
                                         relationUrl={this.model.attrs.wikiToTags.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "wikiToTags_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['wikiToTags_id']}/>
*/}

                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default WikiPagesForm;
