import {ApiRequest, HttpMethod} from "api/api";
import {Pagination} from "../../crud/types/Pagination";
import stringify from "qs-stringify";

const CheckoutType = {
    "Break": -1,
    "Cash": 0,
    "Card": 1,
}


const KassaGetOnlineCheckpoints = (tree: any): Promise<any> => {
    const params = {relationtree: JSON.stringify(tree)};
    return ApiRequest<any>(HttpMethod.GET, "/rapi/user-checkpoints/online", params);
};

const KassaGetCheckpointsHistory = (tree: any, pagination?: Pagination): Promise<any> => {
    const params = {page: pagination?.page ?? 1, relationtree: JSON.stringify(tree)};
    return ApiRequest<any>(HttpMethod.GET, "/rapi/user-checkpoints/index", params);
};

const KassaByBarCode = (barcode: string): Promise<any> => {
    const params = {barcode};
    return ApiRequest<any>(HttpMethod.GET, "/rapi/user-checkpoints/barcode-entrance", params);
};

const KassaCheckout = (id: number, payment: keyof typeof CheckoutType): Promise<any> => {
    const params = {user_checkpoint_id: id, payment_type: CheckoutType[payment]};
    return ApiRequest<any>(HttpMethod.POST, "/rapi/user-checkpoints/close", stringify(params));
};

const Invoices = (tree: any): Promise<any> => {
    const params = {sort: "-id", relationtree: JSON.stringify(tree)};
    return ApiRequest<any>(HttpMethod.GET, "/rapi/user-invoices/index", params);
};

export {KassaByBarCode, KassaGetOnlineCheckpoints, KassaCheckout, KassaGetCheckpointsHistory, CheckoutType, Invoices};