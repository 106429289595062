import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {faTelegram, faWikipediaW} from "@fortawesome/free-brands-svg-icons";
import {Constants} from "../utils/constants";
import {IMenuItem} from "../adminLTE/components/sidebar/MenuSidebar";

export const MENU: IMenuItem[] = [
    {name: 'Касса', icon: solid('cash-register'), path: '/kassa'},
    {
        name: 'Учет антикафе',
        icon: solid('clock'),
        children: [
            {name: 'Посещения', icon: solid('id-card'), path: '/user-checkpoints'},
            {name: 'Оплаты', icon: solid('credit-card'), path: '/user-invoices'},
        ]
    },

    {
        name: 'Клювожоры',
        icon: solid('id-card'),
        children: [
            {name: 'Постоянники', icon: solid('user'), path: '/users'},

            {name: 'Пул карт', icon: solid('credit-card'), path: '/cards'},
            {name: 'Бан-лист', icon: solid('poo'), path: '/ban-list'}
        ]
    },
    {
        name: 'Роли и тарифы',
        icon: solid('money-check-dollar'),
        children: [
            {name: 'Роли', icon: solid('users-gear'), path: '/roles'},
            {name: 'Тарифы', icon: solid('money-check-dollar'), path: '/tarifs'},
            {name: 'Группы ролей', icon: solid('users-gear'), path: '/role-groups'},
        ]
    },
    {
        name: 'Ссылки',
        icon: solid('link'),
        path: "/links",
        children: [
            {
                name: 'Google Календарь',
                newTab: true,
                icon: solid('arrow-up-right-from-square'),
                path: 'https://calendar.google.com/calendar/u/0/r'
            },
            {
                name: 'Касса G.Doc',
                newTab: true,
                icon: solid('arrow-up-right-from-square'),
                path: 'https://docs.google.com/spreadsheets/d/1O0um_QLpjmDd10BnEEGuurz6mRfiXSc5xFKdxeYOqgs/edit#gid=0'
            },
            {
                name: 'Настолки G.Doc',
                newTab: true,
                icon: solid('arrow-up-right-from-square'),
                path: 'https://docs.google.com/spreadsheets/d/16JDwG7Jp8jcGUD7JG4buQueG60m6DWo-WlCCniRAuGE/edit#gid=0'
            }
        ]
    },
    {name: 'Камеры', icon: solid('camera'), path: '/cam'},
    {name: 'Wiki', icon: faWikipediaW, path: '/wiki'},
    {name: 'Модули', path: '#'},

    {
        name: 'Сайт',
        icon: solid('dice-five'),
        children: [
            {
                name: 'Настолки',
                icon: solid('dice-five'),
                children: [
                    {name: 'Игры', icon: solid('puzzle-piece'), path: 'table-games/table-games'},
                    {name: 'Жанры', icon: solid('theater-masks'), path: 'table-games/table-games-themes'}
                ]
            },
            {
                name: 'Мероприятия',
                icon: solid('calendar'),
                children: [
                    {name: 'События', icon: solid('calendar'), path: 'table-games/events'},
                    {name: 'Типы', icon: solid('calendar'), path: 'table-games/event-types'}
                ]
            },
        ]
    },
    {
        name: 'Бот',
        icon: faTelegram,
        children: [
            {name: 'Эмулятор', icon: solid('comments'), path: '/telegrambot/emu'},
            {name: 'Модули', icon: solid('cubes'), path: '/telegrambot/telegrambot-modules'},
            {name: 'Дерево', icon: solid('tree'), path: '/telegrambot/tree'},
            {
                name: 'Прочее',
                icon: solid('code'),
                children: [
                    {name: 'FAQ', icon: solid('comments'), path: '/telegrambot/telegrambot-faq'},
                    {name: 'Cообщения', icon: solid('comments'), path: '/telegrambot/telegrambot-messages'},
                    {name: 'Все команды', icon: solid('code'), path: '/telegrambot/telegrambot-module-commands'},
                    {name: 'Все конфиги', icon: solid('code'), path: '/telegrambot/telegrambot-module-configs'},
                ]
            },
        ]
    },

    {
        name: 'Прочее',
    },

    {
        name: 'DevTools',
        icon: solid('code'),
        children: [
            {
                name: 'Gii-react', newTab: true,
                icon: solid('code'),
                path: 'https://' + Constants.GET_API_SERVER + '/gii-react/yiiReact'
            },
            {
                name: 'Debug Backend', newTab: true,
                icon: solid('code'),
                path: 'https://' + Constants.GET_API_SERVER + '/debug-backend/'
            },
        ]
    },
    {
        name: 'Swagger',
        icon: solid('code'),
        children: [
            {
                name: 'Swagger',
                icon: solid('code'),
                path: '/swagger/'
            },
            {
                name: 'Swagger Telegram',
                icon: solid('code'),
                path: '/swagger/Telegrambot'
            },
            {
                name: 'Swagger TableGames',
                icon: solid('code'),
                path: '/swagger/TableGames'
            },
        ]
    },
];
