import React, {Component} from "react";
import {breadcrumbsStore} from "store/BreadcrumbsStore";
import ErrorWidget from "../../components/ErrorWidget";
import {Translation} from "react-i18next";
import {BaseModelInfo, defaultModel, IRelationTree} from "../../types/BaseModelInfo";
import BaseForm from "../BaseForm";
import {SpaCrudIndex} from "./_index";
import Modal from "react-bootstrap/Modal";
import {SpaCrudView} from "./_view";
import {SpaCrudCreate} from "./_create";

class SpaCrud extends Component<any, any> {
    public model: BaseModelInfo = defaultModel;
    public relationTree: IRelationTree = {}
    public defaultShowAttrs: string[] = []// = ["id", "name"];
    public FormComponent = BaseForm;

    constructor(props: any) {
        super(props);
        this.state = {
            showViewId: 0,
            showCreateModal: false,
        };
    }

    resetBreadcrumbs = () => {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.homeSPA!!!!')}</Translation>, link: '/'},
            {name: this.model.info.label, link: ''}
        ];
    }

    componentDidMount() {
        this.resetBreadcrumbs()
    }

    onCreateClick = () => {
        this.setState({showCreateModal: true})
    }
    onRowClick = (model: any) => {
        this.setState({showViewId: model.id})
    }


    render() {
        if (this.state.hasError) {
            return <ErrorWidget/>;
        }

        return (
            <>
                <Modal size={"lg"}
                       show={(this.state.showViewId > 0)}
                       onHide={() => {
                           this.setState({showViewId: 0})
                           this.resetBreadcrumbs()
                       }}>

                    <SpaCrudView
                        modelInfo={this.model}
                        onCloseThis={() => {
                            this.setState({showViewId: 0})
                            this.resetBreadcrumbs()
                        }}
                        match={{params: {id: this.state.showViewId}}}
                    />

                </Modal>
                <Modal size={"lg"}
                       show={this.state.showCreateModal}
                       onHide={() => {
                           this.setState({showCreateModal: false});
                           this.resetBreadcrumbs()
                       }}>

                    <SpaCrudCreate modelInfo={this.model} form={this.FormComponent}/>

                </Modal>

                <SpaCrudIndex modelInfo={this.model} onCreateClick={this.onCreateClick} onRowClick={this.onRowClick}/>

            </>
        );
    }
}

// onCreateClick: () => void;
//     onRowClick: () => void;
export default SpaCrud;