/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TelegrambotMessagesModel: BaseModelInfo = {
    info: {
        name: "telegrambot-messages",
        label: "Telegrambot messages",
        apiPath: "/telegrambot/api/telegrambot-messages",
        viewPath: "/telegrambot/telegrambot-messages"
    },
    attrs: {
        id: {label: "ID"},
        message_id: {label: "Id сообщения"},
        from_id: {label: "Telegram_chat_id пользователя"},
        chat_id: {label: "Chat_id назначения сообщения"},
        from_username: {label: "Username пользователя"},
        text: {label: "Текст сообщения"},
        is_have_inline_menu: {label: "Флаг inline menu"},
        is_removed_inline_menu: {label: "Флаг удаления inline menu"},
        created_at: {label: "Дата создания"},
        updated_at: {label: "Дата обновления"},
        user: {label: '"User"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {},
    defaultShowAttrs: ["message_id", "from_id", "chat_id", "from_username", "text",]
};


