/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TelegrambotFaqCreate from './create';
import TelegrambotFaqUpdate from './update';
import TelegrambotFaqView from './view';
import TelegrambotFaqIndex from './index';
import {TelegrambotFaqModel} from "./_model";

const TelegrambotFaqRoutes = [
    <>
        <Route path={TelegrambotFaqModel.info.viewPath} element={<TelegrambotFaqIndex/>}/>
        <Route path={TelegrambotFaqModel.info.viewPath + '/create'} element={<TelegrambotFaqCreate/>}/>
        <Route path={TelegrambotFaqModel.info.viewPath + '/update/:id'} element={<TelegrambotFaqUpdate/>}/>
        <Route path={TelegrambotFaqModel.info.viewPath + '/view/:id'} element={<TelegrambotFaqView/>}/>
    </>
];
export default TelegrambotFaqRoutes;
