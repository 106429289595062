import {Button, Card, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {ApiRequest, HttpMethod} from "../../../api/api";
import {toast} from "react-toastify";
import IndexDataLoader from "../../../ui/loaders/IndexDataLoader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faPencil, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import CommandsModal from "./commandsModal";


type Props = {
    moduleId: number,
    moduleCommands: any,
    callback: any,
}
export default function CommandsCard(props: Props) {
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [isDeleteModal, setDeleteModal] = useState(0);
    const [isUpdateModal, setUpdateModal] = useState<any | null>(null);
    const [data, setData] = useState([]);

    const getData = () => {
        setDataLoaded(false)
        ApiRequest<any>(HttpMethod.GET, "/telegrambot/api/telegrambot-module-commands/get-module-commands/?module_id=" + props.moduleId)
            .then((resp: any) => {
                console.log(resp.data)
                setData(resp?.data ?? []);
                setDataLoaded(true)
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed get relation'));

    }
    useEffect(() => {
            if (props.moduleId) {
                getData();
            }

        },
        [props])


    return <>

        <CommandsModal module_id={props.moduleId} data={isUpdateModal} onClose={() => {
            setUpdateModal(null);
            props.callback()
        }}/>


        <Modal show={(isDeleteModal > 0)}>
            <Modal.Header>
                <h5 className="modal-title">Удаление</h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <p>А их можно вообще удалить?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => {
                    ApiRequest<any>(HttpMethod.GET, "/telegrambot/api/telegrambot-module-commands/delete?id=" + isDeleteModal)
                        .then((resp: any) => {
                            console.log(resp.data)
                            toast.success('Удалено!')
                            props.callback()
                        })
                        .catch((error) => toast.error(error?.response?.data?.message || 'Failed save'));
                    setDeleteModal(0);
                }}>Удалить</Button>
                <Button variant="secondary" onClick={() => setDeleteModal(0)}>Закрыть</Button>
            </Modal.Footer>
        </Modal>

        <Card className="card-outline card-success p-1">
            <Card.Header><Card.Title>Команды модуля</Card.Title></Card.Header>
            <Card.Body>
                {
                    (isDataLoaded)
                        ? data.map((value: any, index) => {
                            const obj = props.moduleCommands?.find((obj: any) => obj.name === value.name);
                            return (obj)
                                ? <Row key={index} className="">
                                    <span className="m-1 btn btn-outline-primary">/{obj?.name}</span>
                                    <span className="m-1 btn btn-info">{obj?.descr}</span>

                                    <span
                                        onClick={() => setUpdateModal({...obj, isNew: false, id: obj.id})}
                                        className="m-1 btn btn-outline-success">
                                        <FontAwesomeIcon icon={faPencil}/>
                                    </span>
                                    <span
                                        onClick={() => setDeleteModal(obj.id)}
                                        className="m-1 btn btn-outline-danger">
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                    </span>
                                    <hr className="p-0 m-0"/>
                                </Row>

                                : <Row key={index} className="">
                                    <span className="m-1 btn btn-outline-primary disabled">/{value?.id}</span>
                                    <span className="m-1 btn btn-info disabled">{value?.descr}</span>
                                    <span
                                        onClick={() => setUpdateModal({...value, isNew: true})}
                                        className="m-1 btn btn-outline-success">
                                        <FontAwesomeIcon icon={faAdd}/>
                                    </span>
                                    <hr className="p-0 m-0"/>
                                </Row>

                        })
                        : <IndexDataLoader/>
                }
            </Card.Body>
        </Card>
    </>
}