/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {RolesModel} from "./_model";

class RolesIndex extends BaseIndexPage {
    //public model = RolesModel;

    constructor(props: any) {
        super(props);
        super.model = RolesModel;
        super.defaultShowAttrs = RolesModel.defaultShowAttrs;
        super.relationTree = RolesModel.relationTree
    }
}

export default RolesIndex;