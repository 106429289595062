/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TelegrambotUserTriggersModel} from "./_model";

class TelegrambotUserTriggersIndex extends BaseIndexPage {
    //public model = TelegrambotUserTriggersModel;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotUserTriggersModel;
        super.defaultShowAttrs = TelegrambotUserTriggersModel.defaultShowAttrs;
        super.relationTree = TelegrambotUserTriggersModel.relationTree
    }
}

export default TelegrambotUserTriggersIndex;