/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import EventsForm from "./_form";
import {EventsModel} from "./_model";


class EventsCreate extends BaseCreatePage {
    //public model = EventsModel;
    //public FormComponent = EventsForm;

    constructor(props: any) {
        super(props);
        super.model = EventsModel;
        super.FormComponent = EventsForm;
        super.relationTree = EventsModel.relationTree
    }
}

export default EventsCreate;