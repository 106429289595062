/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TelegrambotUserMessagesCreate from './create';
import TelegrambotUserMessagesUpdate from './update';
import TelegrambotUserMessagesView from './view';
import TelegrambotUserMessagesIndex from './index';
import {TelegrambotUserMessagesModel} from "./_model";

const TelegrambotUserMessagesRoutes = [
    <>
        <Route path={TelegrambotUserMessagesModel.info.viewPath} element={<TelegrambotUserMessagesIndex/>}/>
        <Route path={TelegrambotUserMessagesModel.info.viewPath + '/create'}
               element={<TelegrambotUserMessagesCreate/>}/>
        <Route path={TelegrambotUserMessagesModel.info.viewPath + '/update/:id'}
               element={<TelegrambotUserMessagesUpdate/>}/>
        <Route path={TelegrambotUserMessagesModel.info.viewPath + '/view/:id'}
               element={<TelegrambotUserMessagesView/>}/>
    </>
];
export default TelegrambotUserMessagesRoutes;
