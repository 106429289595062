/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TelegrambotMessagesModel} from "./_model";

class TelegrambotMessagesIndex extends BaseIndexPage {
    //public model = TelegrambotMessagesModel;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotMessagesModel;
        super.defaultShowAttrs = TelegrambotMessagesModel.defaultShowAttrs;
        super.relationTree = TelegrambotMessagesModel.relationTree
    }
}

export default TelegrambotMessagesIndex;