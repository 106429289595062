import {makeAutoObservable, observable} from 'mobx'
import axios from "axios";
import {User} from "../models/users/User";


export class AuthStore {
    get profile(): User | null {
        return this._profile;
    }

    set profile(value: User | null) {
        this._profile = value;
        localStorage.setItem("profile", JSON.stringify(value));
    }

    constructor() {
        //constructor(token:any, status:any) {
        //   this._token = token;
        //   this.status = status;
        makeAutoObservable(this);
        this.token = this.getAccessToken() ?? "";
        this._profile = JSON.parse(localStorage.getItem("profile") ?? "{}");
    }


    get isAuthorized(): boolean {

        return (this._token.length > 5);
    }

    @observable status: any;
    @observable private _token: string = "";
    @observable private _profile: User | null = null;

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        localStorage.setItem("token", value);
        this._token = value;
        axios.defaults.headers.common = {'Authorization': `Bearer ${this._token}`}
    }

    logout() {
        console.log("call authStore logout")
        this.token = "";
        localStorage.setItem("token", "");
        axios.defaults.headers.common = {'Authorization': ``}
    }

    getAccessToken() {
        return localStorage.getItem("token");
    }

}


export const authStore = new AuthStore()