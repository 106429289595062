/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {EventsModel} from "./_model";
import ErrorWidget from "../../../crud/components/ErrorWidget";
import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import TableIndex from "../../../crud/TableIndex/TableIndex";
import MainLoader from "../../../ui/loaders/MainLoader";
import {Pagination} from "../../../crud/types/Pagination";
import EventsCalendar from "./calendar";
import CollapsedCard from "../../../adminLTE/components/CollapsedCard";

class EventsIndex extends BaseIndexPage {
    //public model = EventsModel;

    constructor(props: any) {
        super(props);

        super.model = EventsModel;
        super.defaultShowAttrs = EventsModel.defaultShowAttrs;
        super.relationTree = EventsModel.relationTree
        super.state = {
            data: {data: [], pagination: new Pagination()},
            isLoadedFirstPage: false,
            hasError: false,
            hasMore: true,
            sortBy: "-id", //id,-id, someField
            searchArray: {}, //id=2&someField=someValue&
            showAttr: {},
        };

        //super.setState({sortBy:})
    }


    render() {
        if (this.state.hasError) {
            return <ErrorWidget/>;
        }

        return (
            <>
                <Row>
                    <Col md={6}>
                        <CollapsedCard title="Календарь мероприятий" initialOpen={true}>
                                <EventsCalendar/>
                        </CollapsedCard>
                    </Col>
                    <Col md={6}>

                    </Col>
                </Row>
                <Card className="card-outline card-success">
                    <Card.Header>
                        <Card.Title>{this.model.info.label}</Card.Title>
                        <div className="card-tools">
                            <Link className="btn-outline-success btn btn-sm" to={'create'}>Create</Link>
                        </div>
                    </Card.Header>

                    <Card.Body className="p-0">
                        {this.state.isLoadedFirstPage ? (
                            <>
                                {/* <InfiniteScroll
                                dataLength={this.state.data.data.length}
                                next={this.loadMore}
                                //hasMore={this.state.hasMore}
                                hasMore={false}
                                loader={<MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>}
                                //endMessage={<p style={{textAlign: 'center'}}>На этом все</p>}
                                endMessage=""*/}
                                <TableIndex
                                    model={this.model}
                                    data={this.state.data.data}
                                    enabledAttributes={this.defaultShowAttrs}
                                    actions={{update: {title: "update"}}}
                                    pagination={{data: this.state.data.pagination, callback: this.actionPagination}}
                                    sortBy={this.state.sortBy ?? "-id"}
                                    onSortChange={this.onSortChange}
                                    searchQuery={this.state.searchArray}
                                    onSearch={this.onSearch}
                                    onRelationTreeChange={this.onTreeChange}
                                />
                                {/*  <span>
                                Страница: {this.state.data.pagination.page} из {this.state.data.pagination.pageCount}
                                <br/>
                            Элементы: {this.state.data.pagination.pageSize}/{this.state.data.pagination.totalItems},
                            </span>*/}
                            </>
                        ) : (
                            <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>
                        )}
                    </Card.Body>
                </Card>



            </>

        );
    }
}

export default EventsIndex;