import {makeAutoObservable, observable} from 'mobx'
import {CheckpointUser} from "../../models/users/UserCheckpoint";
import {KassaGetOnlineCheckpoints} from "../kassa/kassa";
import {toast} from "react-toastify";

export class CheckoutStore {
    constructor() {
        this._checkpoints = []
        this._currentCheckpoint = null;
        makeAutoObservable(this);
    }

    @observable private _checkpoints: Array<CheckpointUser>;

    get checkpoints(): Array<CheckpointUser> {
        return this._checkpoints;
    }

    set checkpoints(value: Array<CheckpointUser>) {
        this._checkpoints = value;
    }

    @observable private _currentCheckpoint: CheckpointUser | null;

    get currentCheckpoint(): CheckpointUser | null {
        return this._currentCheckpoint;
    }

    set currentCheckpoint(value: CheckpointUser | null) {
        this._currentCheckpoint = value;
    }

    getData = () => {
        const treeJson = {
            relation: {
                tarif: [],
                user: {
                    fields: ['id', 'image_name', 'name', 'barcode', 'balance'],
                    relation: {'role': []}
                }
            }
        }

        KassaGetOnlineCheckpoints(treeJson)
            .then((resp: any) => this.checkpoints = resp.data)
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));

    }
}


export const checkoutStore = new CheckoutStore()