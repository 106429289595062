/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import TelegrambotUserTriggersForm from "./_form";
import {TelegrambotUserTriggersModel} from "./_model";


class TelegrambotUserTriggersCreate extends BaseCreatePage {
    //public model = TelegrambotUserTriggersModel;
    //public FormComponent = TelegrambotUserTriggersForm;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotUserTriggersModel;
        super.FormComponent = TelegrambotUserTriggersForm;
        super.relationTree = TelegrambotUserTriggersModel.relationTree
    }
}

export default TelegrambotUserTriggersCreate;