/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TelegrambotMessagesCreate from './create';
import TelegrambotMessagesUpdate from './update';
import TelegrambotMessagesView from './view';
import TelegrambotMessagesIndex from './index';
import {TelegrambotMessagesModel} from "./_model";

const TelegrambotMessagesRoutes = [
    <>
        <Route path={TelegrambotMessagesModel.info.viewPath} element={<TelegrambotMessagesIndex/>}/>
        <Route path={TelegrambotMessagesModel.info.viewPath + '/create'} element={<TelegrambotMessagesCreate/>}/>
        <Route path={TelegrambotMessagesModel.info.viewPath + '/update/:id'} element={<TelegrambotMessagesUpdate/>}/>
        <Route path={TelegrambotMessagesModel.info.viewPath + '/view/:id'} element={<TelegrambotMessagesView/>}/>
    </>
];
export default TelegrambotMessagesRoutes;
