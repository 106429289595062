/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import {Route} from "react-router-dom";

import WikiIndex from "./index";

const WikiRoutes = [
    <>
        <Route path="/wiki" element={<WikiIndex/>}/>
        <Route path="/wiki/:id" element={<WikiIndex/>}/>
    </>
];
export default WikiRoutes;
