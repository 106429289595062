/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {RoleGroupsModel} from "./_model";

class RoleGroupsIndex extends BaseIndexPage {
    //public model = RoleGroupsModel;

    constructor(props: any) {
        super(props);
        super.model = RoleGroupsModel;
        super.defaultShowAttrs = RoleGroupsModel.defaultShowAttrs;
        super.relationTree = RoleGroupsModel.relationTree
    }
}

export default RoleGroupsIndex;