/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import RelationOneDropDown from "crud/FormInputs/RelationOneDropDown";
import {TelegrambotModulesModel} from "./_model";

class TelegrambotModulesForm extends BaseForm {
    //public model = TelegrambotModulesModel;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotModulesModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                parent_module_id: '',
                name: '',
                descr: '',
                help_message: '',
                done_message: '',
                undone_message: '',
                version: '',
                created_at: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        console.log("this.model.attrs.parent_module_id.params.model")
        console.log(this.model.attrs.parent_module_id.params.model)
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>

                    <RelationOneDropDown
                        name={'name'}
                        label={this.model.attrs.name.label}
                        model={this.model.info.name}
                        relationUrl={this.model.attrs.name.params?.model?.info?.apiPath}
                        onChange={(val: any) => {
                            let form = this.state.form;
                            form["descr"] = val.descr;
                            form["help_message"] = val.help_message;
                            form["done_message"] = val.done_message;
                            form["undone_message"] = val.undone_message;
                            form["version"] = val.version;
                            this.handleInputChange({name: "name", value: val.id});
                        }}
                        value={this.state.form['name']}
                        class=""/>

                    <InputField name={'descr'}
                                model={this.model.info.name}
                                label={this.model.attrs.descr.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['descr']}/>

                    <InputField name={'help_message'}
                                model={this.model.info.name}
                                label={this.model.attrs.help_message.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['help_message']}/>

                    <InputField name={'done_message'}
                                model={this.model.info.name}
                                label={this.model.attrs.done_message.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['done_message']}/>

                    <InputField name={'undone_message'}
                                model={this.model.info.name}
                                label={this.model.attrs.undone_message.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['undone_message']}/>

                    <InputField name={'version'}
                                model={this.model.info.name}
                                label={this.model.attrs.version.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.version}/>

                    <InputField name={'created_at'}
                                model={this.model.info.name}
                                label={this.model.attrs.created_at.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.created_at}/>


                    <RelationOneDropDown
                        name={'parent_module_id'}
                        label={this.model.attrs.parent_module_id.label}
                        model={this.model.info.name}
                        relationUrl={this.model.attrs.parent_module_id.params.model?.info?.apiPath + "/index"}
                        onChange={(val: any) => this.handleInputChange({
                            name: "parent_module_id",
                            value: val.id
                        })}
                        value={this.state.form.parent_module_id}
                        class=""/>


                    {/* <RelationOneDropDown name={'parent_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.parent_id?.label}
                                         relationUrl={this.model.attrs.parent.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "parent_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['parent_id']}/>*/}

                    {/* <RelationOneDropDown name={'childrens_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.childrens_id?.label}
                                         relationUrl={this.model.attrs.childrens.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "childrens_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['childrens_id']}/>

                    <RelationOneDropDown name={'telegrambotModuleCommands_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.telegrambotModuleCommands_id?.label}
                                         relationUrl={this.model.attrs.telegrambotModuleCommands.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "telegrambotModuleCommands_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['telegrambotModuleCommands_id']}/>

                    <RelationOneDropDown name={'telegrambotModuleConfigs_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.telegrambotModuleConfigs_id?.label}
                                         relationUrl={this.model.attrs.telegrambotModuleConfigs.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "telegrambotModuleConfigs_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['telegrambotModuleConfigs_id']}/>

                    <RelationOneDropDown name={'telegrambotModuleDatas_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.telegrambotModuleDatas_id?.label}
                                         relationUrl={this.model.attrs.telegrambotModuleDatas.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "telegrambotModuleDatas_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['telegrambotModuleDatas_id']}/>

                    <RelationOneDropDown name={'telegrambotModuleDepends_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.telegrambotModuleDepends_id?.label}
                                         relationUrl={this.model.attrs.telegrambotModuleDepends.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "telegrambotModuleDepends_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['telegrambotModuleDepends_id']}/>

                    <RelationOneDropDown name={'telegrambotModuleDepends0_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.telegrambotModuleDepends0_id?.label}
                                         relationUrl={this.model.attrs.telegrambotModuleDepends0.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "telegrambotModuleDepends0_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['telegrambotModuleDepends0_id']}/>*/}


                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default TelegrambotModulesForm;
