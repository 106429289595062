import React from 'react'
import {Form, InputGroup} from "react-bootstrap";
import {FieldInputProps} from "../types/FieldInputProps";

export default function SwitchField(props: FieldInputProps) {
    //const [validated, setValidated] = useState<boolean | undefined>();

    const onChangeValidate = (event: any) => {
        var value = event?.target?.checked ? 1 : 0;
        props.onChange({name: props.name, value: value.toString()})
        /* if (props.model !== undefined && props.model !== '') {
             validate(
                 props.model,
                 props.name,
                 event.target.value,
                 () => {
                     setState({helpBlock: ''})
                     setState({validation: true})
                 },
                 (err: any) => {
                     setState({helpBlock: err})
                     setState({validation: false})
                 }).then();
         }*/
    }

    return (
        <InputGroup className={'form-group w-100 field-' + props.model + '-' + props.name}>
            <InputGroup.Text style={{backgroundColor: "#FFF"}}>
                <Form.Check
                    type="switch"
                    required={props.required}
                    id={props.model + '-' + props.name}
                    //className={'form-control ' + classPostfix(validated)}
                    name={props.name}
                    label={props.label}
                    placeholder={props.placeholder}
                    checked={props.value}
                    value={props.value || ''}
                    onChange={onChangeValidate}
                    {...props.pluginProps}
                />
            </InputGroup.Text>
        </InputGroup>
    )
}

