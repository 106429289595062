/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import React, {useEffect, useState} from "react";
import {uiStore} from "../../store/UiStore";
import {Card, Col, Row} from "react-bootstrap";
import WikiSidebar, {IWikiMenuItem} from "./menu/WikiSidebar";
import {ApiRequest, HttpMethod} from "../../api/api";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import {Translation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import WikiCategories from "./wiki-categories";
import WikiPagesIndex from "./wiki-pages";
import ReactMarkdown from "react-markdown";


export default function WikiIndex() {
    //public model = WikiModel;
    let {id} = useParams();

    const [menu, setMenu] = useState<IWikiMenuItem[]>([]);
    const [page, setPage] = useState<any>();


    const parseTreeItem = (cats: any[]) => {
        //console.log("parseTreeItem cats:", cats)
        return cats.map((cat: any) => {
            //console.log("parseTreeItem map ", cat)
            let _menu: IWikiMenuItem = {name: cat.name, id: cat.id, icon: solid('folder'), children: []}
            if (cat.children.length >= 1) {
                _menu.children?.push(...parseTreeItem(cat.children))
            }
            return _menu;
        })
    }

    const getTree = () => {
        const params = {relationtree: JSON.stringify({relations: {wikiPages: []}})};
        ApiRequest<any>(HttpMethod.GET, "/rapi/wiki-categories/get-tree", params)
            .then((resp) => {
                let _menu = parseTreeItem(resp.data)
                //console.log(_menu)
                setMenu(_menu)
            })
    }

    const getCategory = (pageTitle: string) => {
        const params = {relationtree: JSON.stringify({relations: ["wikiPages"]})};
        ApiRequest<any>(HttpMethod.GET, "/rapi/wiki-categories/index?page=0&pageSize=12&sort=&search=name%3D" + pageTitle, params)
            .then((resp) => {
                if (resp.data[0]) {
                    console.log("viewDir", resp.data[0])
                    let pages = resp.data[0].wikiPages.map((page: any) => {
                        return `\n[${page.title}](/wiki/${page.title})\n`
                    })

                    let viewDir = {
                        isDir: true,
                        text: "\n ## Категория: " + resp.data[0].name + "\n"
                            + pages.join("\n\n")
                    }
                    setPage(viewDir)
                } else {

                }
            })
    }

    const getPage = (pageTitle: string) => {
        const params = {relationtree: JSON.stringify({relations: {wikiPages: []}})};
        /** /rapi/wiki-pages/index?page=0&pageSize=12&sort=&search=title%3D%25D0%25B5&relationtree=%7B%7D **/
        ApiRequest<any>(HttpMethod.GET, "/rapi/wiki-pages/index?page=0&pageSize=12&sort=&search=title%3D" + pageTitle, params)
            .then((resp) => {
                if (resp.data[0]) {
                    console.log("setPage", resp.data[0])
                    setPage(resp.data[0])
                } else {
                    getCategory(pageTitle)
                }
            })
    }

    useEffect(() => {
        if (id) {
            getPage(id);
            breadcrumbsStore.items = [
                {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
                {name: "Wiki", link: '/wiki'},
                {name: id}
            ];
        }
        // setPage(" ### setPage: "+id )
    }, [id]); //

    useEffect(() => {
        uiStore.toggleMenuSidebarCollapsed()
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: "Wiki"}
        ];
        getTree()

        return () => {
            // Этот код выполнится при размонтировании компонента
            uiStore.toggleMenuSidebarCollapsed()
        };
    }, []); // Пустой массив зависимостей означает, что эффект выполнится только при монтировании и размонтировании компонента

    const renderLink = (props: any) => {
        return <Link to={props.href}>{props.children}</Link>;
    };

    return (
        <Row className="p-0 m-0">
            <Col md={3}>
                <Card>
                    <WikiSidebar menu={menu} showSearch={true}/>
                </Card>
                <small>try open <b>{id}</b></small>
                <br/>

                <Link to={"/wiki/wiki-pages"}
                      className=" btn btn-sm btn-outline-primary  m-1">
                    WikiPagesCrud
                </Link>

                <Link to={"/wiki/wiki-categories"}
                      className=" btn btn-sm btn-outline-primary  m-1">
                    WikiCategoriesCrud
                </Link>



            </Col>
            <Col md={9}>
                <Card>
                    <Card.Body>
                        <Row>
                            {
                                (page?.isDir) &&
                                <Link to={"/wiki/wiki-pages/create"}
                                      className="float-right btn btn-sm btn-outline-primary  m-1">
                                    Add
                                </Link>
                            }
                            {
                                (page?.id) &&
                                <Link to={"/wiki/wiki-pages/update/" + page?.id}
                                      className="float-right btn btn-sm btn-outline-primary m-1">
                                    Edit
                                </Link>
                            }
                        </Row>
                        <ReactMarkdown
                            //transformLinkUri
                            //transformImageUri
                            components={{a: renderLink}}
                            children={page?.text ?? ""}
                        />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );

}
