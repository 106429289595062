/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {UserCheckpointsModel} from "./_model";

class UserCheckpointsIndex extends BaseIndexPage {
    //public model = UserCheckpointsModel;

    constructor(props: any) {
        super(props);
        super.model = UserCheckpointsModel;
        super.defaultShowAttrs = UserCheckpointsModel.defaultShowAttrs;
        super.relationTree = UserCheckpointsModel.relationTree
    }
}

export default UserCheckpointsIndex;