/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import RelationOneDropDown from "crud/FormInputs/RelationOneDropDown";
import {TelegrambotUserMessagesModel} from "./_model";

class TelegrambotUserMessagesForm extends BaseForm {
    //public model = TelegrambotUserMessagesModel;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotUserMessagesModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                user_id: '',
                is_answered: '',
                type: '',
                user_triggers: '',
                message: '',
                created_at: '',
                updated_at: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    <InputField name={'id'}
                                model={this.model.info.name}
                                label={this.model.attrs.id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['id']}/>

                    <InputField name={'user_id'}
                                model={this.model.info.name}
                                label={this.model.attrs.user_id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['user_id']}/>

                    <InputField name={'is_answered'}
                                model={this.model.info.name}
                                label={this.model.attrs.is_answered.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['is_answered']}/>

                    <InputField name={'type'}
                                model={this.model.info.name}
                                label={this.model.attrs.type.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['type']}/>

                    <InputField name={'user_triggers'}
                                model={this.model.info.name}
                                label={this.model.attrs.user_triggers.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['user_triggers']}/>

                    <InputField name={'message'}
                                model={this.model.info.name}
                                label={this.model.attrs.message.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['message']}/>

                    <InputField name={'created_at'}
                                model={this.model.info.name}
                                label={this.model.attrs.created_at.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['created_at']}/>

                    <InputField name={'updated_at'}
                                model={this.model.info.name}
                                label={this.model.attrs.updated_at.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['updated_at']}/>


                    <RelationOneDropDown name={'telegrambotUserMessageAnswers_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.telegrambotUserMessageAnswers_id?.label}
                                         relationUrl={this.model.attrs.telegrambotUserMessageAnswers.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "telegrambotUserMessageAnswers_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['telegrambotUserMessageAnswers_id']}/>

                    <RelationOneDropDown name={'user_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.user_id?.label}
                                         relationUrl={this.model.attrs.users.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "user_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['user_id']}/>


                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default TelegrambotUserMessagesForm;
