/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TelegrambotFaqModel} from "./_model";

class TelegrambotFaqIndex extends BaseIndexPage {
    //public model = TelegrambotFaqModel;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotFaqModel;
        super.defaultShowAttrs = TelegrambotFaqModel.defaultShowAttrs;
        super.relationTree = TelegrambotFaqModel.relationTree
    }
}

export default TelegrambotFaqIndex;