import React from 'react';
import {Card, Modal} from "react-bootstrap";
import {CheckoutType, KassaCheckout} from "../../../api/kassa/kassa";
import {toast} from "react-toastify";
import {checkoutStore} from "../../../api/store/CheckoutStore";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {UsersModel} from "../../users/_model";
import MomentViewer from "../../../crud/field_viewers/MomentViewer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";


export const CheckpointModal = observer(() => {
    const navigate = useNavigate();

    const hasStop = (): boolean => (checkoutStore.currentCheckpoint?.sum ?? 0) > (checkoutStore.currentCheckpoint?.tarif?.stop_check_price ?? 0)

    const onClose = () => {
        checkoutStore.currentCheckpoint = null;
        checkoutStore.getData()
    }

    const checkout = (id: number, type: keyof typeof CheckoutType) => {
        KassaCheckout(id, type)
            .then((resp: any) => {
                console.log(resp)
                //setCurrentUsers(resp.data);
                onClose()
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }

    return <Modal size="lg" show={(checkoutStore.currentCheckpoint != null)} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Посетитель #{checkoutStore.currentCheckpoint?.user?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Card>
                <Card.Body>
                    <table className="table table-sm">
                        <tbody>
                        <tr>
                            <td>Карта</td>
                            <td>{checkoutStore.currentCheckpoint?.user?.barcode}</td>
                        </tr>
                        <tr>
                            <td>Имя</td>
                            <td>
                                <button type="button"
                                        onClick={() => navigate(UsersModel.info.viewPath
                                            + "/view/"
                                            + checkoutStore.currentCheckpoint?.user?.id
                                        )}
                                        className="btn btn-outline-info btn-sm"
                                >

                                    {checkoutStore.currentCheckpoint?.user?.name}
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td>Роль</td>
                            <td>{checkoutStore.currentCheckpoint?.user?.role?.name}</td>
                        </tr>
                        <tr>
                            <td>Тариф</td>
                            <td>{checkoutStore.currentCheckpoint?.tarif.name}</td>
                        </tr>

                        <tr>
                            <td>Время прихода</td>
                            <td><MomentViewer value={checkoutStore.currentCheckpoint?.created_at}/></td>
                        </tr>
                        <tr>
                            <td>Времени провел</td>
                            <td>{checkoutStore.currentCheckpoint?.minutes} мин</td>
                        </tr>
                        <tr>
                            <td>Итого</td>
                            {
                                (hasStop())
                                    ? <td>
                                        {checkoutStore.currentCheckpoint?.sum}₽ /
                                        <span className="text-success">
                                            {checkoutStore.currentCheckpoint?.tarif?.stop_check_price}₽
                                        </span>


                                    </td>
                                    : <td>
                                        {checkoutStore.currentCheckpoint?.sum}₽
                                        <button type="button"
                                                onClick={() => {
                                                    toast.warning("Корректировка суммы не доступна!")
                                                }}
                                                className="ml-1 btn btn-outline-info btn-sm"
                                        >
                                            <FontAwesomeIcon icon={faPencil}/>

                                        </button>
                                    </td>
                            }
                        </tr>
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
            <button type="button" className="btn btn-default btn-lg" data-dismiss="modal">НазаД</button>
            <button type="button" className="btn btn-outline-danger btn-lg"
                    onClick={() => {
                        checkout(checkoutStore.currentCheckpoint!.id, "Break");
                        onClose()
                    }} data-dismiss="modal">
                Туй
            </button>
            <button type="button"
                    onClick={() => checkout(checkoutStore.currentCheckpoint!.id, "Cash")}
                    className="btn btn-outline-success btn-lg"
            >
                Наличные
                ({hasStop() ? checkoutStore.currentCheckpoint?.tarif.stop_check_price : checkoutStore.currentCheckpoint?.sum}₽)
            </button>

            <button type="button"
                    onClick={() => checkout(checkoutStore.currentCheckpoint!.id, "Card")}
                    className="btn btn-outline-primary btn-lg"
            >
                По карте ({checkoutStore.currentCheckpoint?.sum}₽)
            </button>
            {/* <button type="button" className="btn btn-outline-warning  btn-lg">Перевод (798руб)</button>*/}
        </Modal.Footer>
    </Modal>
})
