import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import RelationManyModal from "./RelationManyModal";
import {Row} from "react-bootstrap";
import {FieldViewProps} from "../types/FieldViewProps";
import {toast} from "react-toastify";


/*
type Props = {
    /!** Current Attr **!/
    attr: AttrInfo
    /!** Value Attr **!/
    value: any

    model?: BaseModelInfo
    params?: any
}
*/

interface State {
    showModal: boolean
}

/**
 * attr.params.name
 * attr.params.relationUrl
 */

//const key = Object.keys(attrs).find((k) => attrs[k] === attr);

class RelationManyViewer extends Component<FieldViewProps, State> {
    constructor(props: FieldViewProps) {
        super(props);
        this.state = {showModal: false}
        //console.log("RelationManyViewer", props);
    }

    onCloseModal = () => this.setState({showModal: false})
    onOpenModal = () => this.setState({showModal: true})

    render() {
        let relationModel = this.props.attr?.params?.model;
        // console.log(this.props.attr?.params?.model?.info.apiPath)
        console.log(relationModel?.info?.viewPath)
        if(!relationModel) toast.warning("Нет модели для: "+this.props.attrName);
        return <Row>
            {this.props.value?.map((v: any, i: number) =>
                <Link className="btn btn-sm btn-outline-primary d-inline m-1"
                      key={this.props.attr.label + i}
                    //to={'/' + camelToYii(this.props.attrName) + '/view/' + v.id}
                      to={relationModel?.info?.viewPath + '/view/' + v.id}
                >
                    {v[this.props.attr.params.name ?? "name"]}
                </Link>)}
            {
                (this.props.attr?.params?.editable ?? true) &&
                <>
                    <a className="btn btn-sm btn-success d-inline m-1" key={this.props.attr.label}
                       onClick={this.onOpenModal}>
                        <FontAwesomeIcon icon={faPencil}/>
                    </a>
                    <RelationManyModal
                        {...this.props}
                        show={this.state.showModal}
                        modelId={this.props.params?.modelId}
                        closeModal={this.onCloseModal}

                    />
                </>
            }
        </Row>
    }
}

export default RelationManyViewer