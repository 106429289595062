import React from "react";
import moment from "moment";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {
    value: any
}

function MomentViewer(props: Props) {
    const popover = (date: any) => (
        <Popover id="popover-pages">
            {/*  <Popover.Title as="h3">Подробнее</Popover.Title>*/}
            <Popover.Content>
                {date}
            </Popover.Content>
        </Popover>
    );


    if (props.value) {
        return (
            <>
                {moment(props.value).calendar()}
                <OverlayTrigger
                    /*trigger="click" */
                    placement="top"
                    overlay={popover(props.value)}
                    defaultShow={false}
                    delay={100}>
                    <FontAwesomeIcon className="pl-1" icon={solid("circle-info")}/>
                </OverlayTrigger>
            </>
        )
    } else {
        return <span className="text-muted">00.00.0000</span>
    }


}

export default MomentViewer