import React, {useEffect, useState} from 'react';
import {Translation, useTranslation} from 'react-i18next';
import SettingsTab from './tabs/SettingsTab';
import AppearanceTab from './tabs/AppearanceTab';
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import {Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {toast} from "react-toastify";
import getProfile from "../../api/profile/Profile";
import AccountTab from "./tabs/AccountTab/AccountTab";
import UserBox from "./userBox";
import {User} from "../../models/users/User";
import CheckpointTab from "./tabs/CheckpointTab";
import {authStore} from "../../store/AuthStore";
import InfoTab from "./tabs/InfoTab";

const Profile = () => {
    const [t] = useTranslation();
    const [profile, setProfile] = useState<User>();

    const relationTree = {
        //TODO not WOrk relation
        relation: [
            "role"
        ],
        relations: {
            userCheckpoints: {
                relations: [
                    "tarif",
                ]
            },
            userImages: [],
            userAuthLogins: [],
            userAuthEmails: [],
            userAuthTelegrams: [],
            /* "userCheckpoints",
             "userImages",
             "userAuthLogins",
             "userAuthEmails",
             "userAuthTelegrams",*/
        },
        counts: [
            //"userCheckpoints",
        ]
    }

    useEffect(() => {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: "Profile"}
        ];
        getProfile(relationTree)
            .then((resp: any) => {
                console.log(resp)
                authStore.profile = resp.data
                setProfile(resp.data)
            })
            .catch((error) => toast.error(error.toString() || 'Failed'));

    }, []);

    return (
        <Container fluid={true}>
            <Row>
                <Col md={3}>
                    <UserBox profile={profile}/>
                </Col>
                <Col md={9}>
                    <Card>
                        <Card.Header>
                            <Tabs defaultActiveKey="InfoTab" className="mb-3" fill>
                                {
                                    /*  <Tab eventKey="activity" title={t('main.label.activity')}>
                                      <ActivityTab/>
                                  </Tab>
                                  <Tab eventKey="timeline" title={t('main.label.timeline')}>
                                      <TimelineTab/>
                                  </Tab>*/
                                }
                                <Tab eventKey="InfoTab" title={t('main.label.info')}>
                                    <InfoTab profile={profile}/>
                                </Tab>
                                <Tab eventKey="settings" title={t('main.label.settings')}>
                                    <SettingsTab profile={profile}/>
                                </Tab>
                                <Tab eventKey="accounts" title={t('main.label.accounts')}>
                                    <AccountTab profile={profile}/>
                                </Tab>
                                <Tab eventKey="appearance" title={t('main.label.appearance')}>
                                    <AppearanceTab/>
                                </Tab>
                                <Tab eventKey="checkpoint" title="Посещения">
                                    <CheckpointTab profile={profile}/>
                                </Tab>
                            </Tabs>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
