import React from 'react'
import {ThreeCircles} from 'react-loader-spinner'

type Props = {
    wrapperStyle?: any,
    wrapperClass?: string
}

export default function MainLoader(props: Props) {
    return (
        <ThreeCircles
            height="100"
            width="100"
            color="#4fa94d"
            wrapperStyle={props.wrapperStyle}
            wrapperClass={props.wrapperClass}
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
        />
    )
}

