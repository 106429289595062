/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {TableGamesModel} from "./_model";
import {Link, Navigate} from "react-router-dom";
import {Button, Card, Col, Row} from "react-bootstrap";
import {Translation} from "react-i18next";
import DataTable from "../../../crud/data_viewers/DataTable";
import TableLoader from "../../../ui/loaders/TableLoader";
import React from "react";
import {BaseDeleteModal} from "../../../crud/dialogs/BaseDeleteModal";
import CollapsedCard from "../../../adminLTE/components/CollapsedCard";
import ShopView from "./ view/siteView";
import TGImageView from "./ view/TGImageView";

class TableGamesView extends BaseViewPage {
    //public model = TableGamesModel;


    constructor(props: any) {
        super(props);
        super.model = TableGamesModel;
        super.relationTree = TableGamesModel.relationTree
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath}/>;
        }

        return (this.state.isDataLoaded)
            ?
            <>
                <Card className="card-outline card-success">
                    <BaseDeleteModal show={this.state.modal} onDelete={this.deleteModel} onClose={this.closeModal}/>
                    <Card.Header>
                        <Row className="justify-content-between">
                            <Col><h1>{this.state.data?.name ?? "#" + this.state.data?.id}</h1></Col>
                            <Col>
                                <div className="float-right">
                                    <Link className="btn btn-primary"
                                          to={this.model.info.viewPath + '/update/' + this.state.data.id}>
                                        <Translation>{(t: any) => t('crud.update')}</Translation>
                                    </Link>
                                    <Button variant="danger" className="ml-2"
                                            onClick={() => this.openModal(this.state.data.id)}>
                                        <Translation>{(t: any) => t('crud.delete')}</Translation>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Header>

                    <div className="p-1">
                        <CollapsedCard title="Доп. фотографии" initialOpen={true}>
                            <TGImageView
                                modelId={this.state.data.id}
                                images={this.state.data.tableGamesImgs}
                            />
                        </CollapsedCard>
                    </div>

                    <Card.Body>
                        <DataTable
                            data={this.state.data}
                            model={this.model}
                            disabledFields={this.disabledAttr}
                        />
                    </Card.Body>
                </Card>

                <CollapsedCard title="Вид на сайте">
                    <ShopView data={this.state.data}/>
                </CollapsedCard>
            </>
            : <TableLoader/>;
    }
}

export default withRouter(TableGamesView);