import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Layout from "ui/Layout";
import 'css/index.scss'
import crudRouters from "pages/routes"
import KassaIndex from "pages/kassa/kassa";
import SwaggerPage from "modules/swagger/swagger";
import Profile from "pages/profile/Profile";
import NotFoundPage from "ui/layout/components/404";
import Index from "pages/index/index";
import LoginPage from "modules/auth/login/loginPage";
import 'ui/i18n/i18n';
import CamIndex from "./pages/cam";
import CamView from "./pages/cam/view";
import 'moment/locale/ru';
import {KassaCash} from "./pages/kassa/cash";
import TelegramEmu from "./pages/telegrambot/emu";
import Tree from "./pages/telegrambot/tree/graph2";
import ChatGpt from "./pages/chatgpt";

const App = () => {
    // const windowSize = useWindowSize();
    //const screenSize = useSelector((state: any) => state.ui.screenSize);
    // const dispatch = useDispatch();

    /* useEffect(() => {
         const size = calculateWindowSize(windowSize.width);
         if (screenSize !== size) {
             dispatch(setWindowSize(size));
         }
     }, [windowSize]);*/

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route path="/" element={<Index/>}/>
                    <Route path="/kassa" element={<KassaIndex/>}/>
                    <Route path="/kassa/cash" element={<KassaCash/>}/>
                    <Route path="/swagger" element={<SwaggerPage/>}/>
                    <Route path="/swagger/:filter" element={<SwaggerPage/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/cam" element={<CamIndex/>}/>
                    <Route path="/cam/:id" element={<CamView/>}/>
                    <Route path='/telegrambot/tree/' element={<Tree/>}/>
                    <Route path='/telegrambot/emu/' element={<TelegramEmu/>}/>
                    <Route path='/chatgpt/' element={<ChatGpt/>}/>
                    {crudRouters}
                    <Route path="*" element={<NotFoundPage/>}/>
                </Route>


                <Route path="/login" element={<LoginPage/>}/>
            </Routes>
            <ToastContainer
                autoClose={3000}
                draggable={false}
                position="top-right"
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnHover
            />
        </BrowserRouter>

    )
}


export default App
