import React, {useEffect, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ruLocale from '@fullcalendar/core/locales/ru';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
import axios from "axios";
import IndexDataLoader from "../../../ui/loaders/IndexDataLoader";
import moment from "moment/moment";
import {useNavigate} from "react-router-dom";

export default function EventsCalendar() {

    const [events, setEvents] = useState<any>();
    const [isDataLoaded, setDataLoaded] = useState(false);
    const navigate = useNavigate();

    const getData = () => {
        axios.get("https://zdbot.zdver.com/api/events/get-for-month?page=0",)
            .then((response) => {
                setEvents(response.data.data)
                console.log(response.data.data)
                setDataLoaded(true)
                return response.data.data;
            })
    }

    useEffect(() => {
        getData()
    }, []);
    //api/events/get-for-week?page=0

    const eventContent = (eventInfo: any) => {
        const {event} = eventInfo;

        return (
            <>
                <div>{event.extendedProps.name}</div>

            </>
        );
    };

    const handleEventMount = (info: any) => {
        const {event} = info;
        console.log(event)
        console.log(event.el)
        //console.log(event.extendedProps)
       // const start = event.extendedProps.start;
       // const end = event.extendedProps.end;
        const title = event.extendedProps.name;
        const element = info.el;
        element.href="/table-games/events/view/"
        element.innerHTML = `
            <a href="#" className="fc-event fc-event-start fc-event-end fc-event-future fc-daygrid-event fc-daygrid-dot-event">
                    <div class="fc-daygrid-event-dot"></div>
                    <div class="fc-event-time">${moment(event.start).format('HH:mm')}</div>
                    <div class="fc-event-title">${title}</div>
                </a>
        `


    };
    const handleEventClick = (e: any) => {
        e.jsEvent.preventDefault();
        //console.log(e)
        //console.log(e.event._def.url)
       // console.log(e.event.extendedProps)
        console.log(e.event.id)
        navigate("/table-games/events/view/"+e?.event?.id)
        ///table-games/events/view/
        //window.open(""e.event._def.url, '_blank')
    }

    return (

        isDataLoaded ? <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin, googleCalendarPlugin, listPlugin]}
                headerToolbar={{
                    left: 'prev,next today',
                    right: 'dayGridMonth,listWeek,timeGridWeek,timeGridDay'  //listWeek
                }}
                locale={ruLocale}
                initialView={'listWeek'}
                eventBackgroundColor="green"
                startParam="start_datetime"
                //endParam="to"ж
                //eventDidMount={handleEventMount}
                eventContent={eventContent}
                eventClick={handleEventClick}
                //listWeek
                // themeSystem: 'bootstrap'
                /* editable={false}
                 selectable={true}
                 selectMirror={true}
                 locale={ruLocale}
                 dayMaxEvents={true}
                 weekends={this.state.weekendsVisible}*/
                //googleCalendarApiKey ='AIzaSyBQ8LWXOMLAh13Yvm082-04Z4g0mD4EyK4'

                events={events}
                /*   eventSources={[

                       // your event source
                       {
                           url: 'https://zdbot.zdver.com/api/events/get-for-month?page=0',
                           method: 'POST',
                           extraParams: {
                               custom_param1: 'something',
                               custom_param2: 'somethingelse'
                           },
                           failure: function () {
                               alert('there was an error while fetching events!');
                           },
                           color: 'yellow',   // a non-ajax option
                           textColor: 'black' // a non-ajax option
                       }

                       // any other sources...

                   ]}*/
                /*events={
                    googleCalendarId: 'abcd1234@group.calendar.google.com'
                }*/
                /* events={{
                     googleCalendarId: '030effa65022b96980379a3524e44a3194f1b44ccf4586ff8c261b757716f4c5@group.calendar.google.com'

                 }}*/


                //initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                // select={this.handleDateSelect}
                // eventContent={renderEventContent} // custom render function
                // eventClick={this.handleEventClick}
                // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                //longPressDelay={1000}
                // eventLongPressDelay={1000}
                // selectLongPressDelay={1000}
                //allDaySlot={false}
                /* you can update a remote database when these fire:
                eventAdd={function(){}}
                eventChange={function(){}}
                eventRemove={function(){}}
                */
            />
            : <IndexDataLoader>

            </IndexDataLoader>


    )
}






