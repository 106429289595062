import {ApiRequest, HttpMethod} from "api/api";
import {Constants} from "../../utils/constants";
import stringify from "qs-stringify";


const getTree = () => ApiRequest(HttpMethod.GET, Constants.ApiVersion + "/telegrambot/telegrambot-modules/get-tree")
const setTree = (modulesTree?: any) => ApiRequest(
    HttpMethod.POST,
    Constants.ApiVersion + "/telegrambot/telegrambot-modules/set-tree",
    stringify({"modules-tree": modulesTree})
)

//const getProfile = (): Promise<Profile> => createApiRequest<Profile>(HttpMethod.GET, "/profile/index");
/*
async function getTree(onResponse: any) {

    ApiRequest(HttpMethod.GET,"/telegram/modules/get-tree")
    await axios.get(Constants.API_URL + "/telegram/modules/get-tree")
        .then((response) => GlobalOnResponse(response, onResponse))
        .catch((error: any) => GlobalOnError(error, onResponse))
}
*/


export {getTree, setTree}