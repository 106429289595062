/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {UsersModel} from "./_model";

class UsersView extends BaseViewPage {
    //public model = UsersModel;

    constructor(props: any) {
        super(props);
        super.model = UsersModel;
        super.relationTree = UsersModel.relationTree
    }
}

export default withRouter(UsersView);