/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {TelegrambotMessagesModel} from "./_model";

class TelegrambotMessagesView extends BaseViewPage {
    //public model = TelegrambotMessagesModel;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotMessagesModel;
        super.relationTree = TelegrambotMessagesModel.relationTree
    }
}

export default withRouter(TelegrambotMessagesView);