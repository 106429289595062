import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import WikiMenuItem from "./WikiMenuItem";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

export interface IWikiMenuItem {
    id?: number;
    name: string;
    icon?: IconDefinition;
    //path?: string;

    children?: Array<IWikiMenuItem>;
}

type Props = {
    menu: IWikiMenuItem[],
    showSearch: boolean,
}

const WikiSidebar = observer((props: Props) => {
    // const {sidebarSkin, menuItemFlat, menuChildIndent} = uiStore;
    const [t] = useTranslation();
    const [searchText, setSearchText] = useState('');

    return <aside className={`wiki-sidebar elevation-0 sidebar-dark-primary`}>
        <div className="sidebar2">
            <div className="form-inline mt-3 w-100">
                <div className="input-group">
                    <input className="form-control bg-gray"
                           type="text" placeholder={t("main.search") + "..."}
                           aria-label="Search" value={searchText}
                           onInput={(e) => setSearchText((e.target as any).value)}
                    />
                    <div className="input-group-append">
                        <button type="button" className="btn btn-sidebar" onClick={() => setSearchText("")}>
                            {
                                (searchText.length === 0) &&
                                <FontAwesomeIcon icon={solid("search")}/>

                            }
                            {
                                (searchText.length > 0) &&
                                <FontAwesomeIcon icon={solid("times")}/>
                            }
                        </button>
                    </div>
                </div>

            </div>

            {/* style={{overflowY: 'hidden'}}*/}
            <nav className="mt-2">
                <ul role="menu"
                    className={`nav nav-pills nav-sidebar flex-column nav-compact nav-flat nav-child-indent`}>
                    {props.menu.map((menuItem: IWikiMenuItem) => (
                        <WikiMenuItem
                            key={menuItem.name + menuItem.id}
                            menuItem={menuItem}
                            searchString={searchText}
                        />
                    ))}
                </ul>
            </nav>
        </div>
    </aside>
});

export default WikiSidebar;