import React from 'react';
import {User} from "../../../models/users/User";
import TableIndex from "../../../crud/TableIndex/TableIndex";
import {UserCheckpointsModel} from "../../user-checkpoints/_model";

type Props = {
    profile?: User
}

const CheckpointTab = (props: Props) => {
    const defaultShowAttrs = ["created_at", "minutes", "sum", "status"];
    //const [checkpoints, setCheckpoints] = useState<Array<CheckpointUser>>([]);
    //const [pagination, setPagination] = useState<Pagination>(new Pagination());

    /*
    const renderRow = () => props?.profile?.userCheckpoints?.map((checkpoint) => {
        return checkpoint.created_at;
    })
    */

    return (
        <div>
            <div className="table-responsive-sm">

                <TableIndex
                    model={UserCheckpointsModel}
                    data={props?.profile?.userCheckpoints ?? []}
                    enabledAttributes={defaultShowAttrs}
                    showSettings={true}
                    sortBy="-id"
                    //onSortChange={this.onSortChange}
                    //searchQuery={this.state.searchString}
                    //onSearch={this.onSearch}
                    onRelationTreeChange={() => {
                    }}
                />
            </div>

            {/*  {renderRow()}*/}
        </div>
    );
};

export default CheckpointTab;
