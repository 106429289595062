import React, {useState} from 'react';
import {Pagination} from '../../adminLTE';
import {AttrInfoArray} from "../types/AttrInfo";
import {PaginationCallback} from "../types/Pagination";
import {FieldType} from "../types/FieldType";
import {BaseModelInfo} from "../types/BaseModelInfo";
import TableViewHeader from "./tableViewHeader";
import TableViewSearch from "./tableViewSearch";
import TableViewRows from "./tableViewRows";

type Props = {
    /** Model Info */
    model: BaseModelInfo,
    /** data array. */
    data: any[],
    /** Model attributes to be rendered. Ex: {id: 'Item Id', name: 'Item Name'} */
    enabledAttributes: string[],
    /** Model actions to manipulate selected row. Ex: {view: {title: "view", icon: (Optional) HTML markup or Font Awesome>}, update: {title: "update"},} */
    actions?: any,
    pagination?: PaginationCallback,
    sortBy?: string,
    onSortChange?: any,
    onSearch?: any,
    onRelationTreeChange: (value: AttrInfoArray) => void;
    searchQuery?: object,
    onCreateClick?: any,
    onRowClick?: (data: any) => void,
    showSettings?: boolean,
    /** Text or HTML markup to be rendered if table is empty. Default value: 'Create your first item' */
    ifEmpty?: any,
};

export default function TableIndex(props: Props) {
    const [enabledAttributes, setEnabledAttributes] = useState<string[]>(props.enabledAttributes);

    const toggleAttrShow = (attr: string) => {
        if (enabledAttributes.find((i: any) => i === attr)) {
            setEnabledAttributes(enabledAttributes.filter((i: any) => i !== attr))
        } else {
            setEnabledAttributes([...enabledAttributes, attr]);

            // find enabled relations
            let relationAttr: AttrInfoArray = {};

            [...enabledAttributes, attr].forEach((v: any) => {
                let attr = props.model?.attrs[v];
                if (attr?.type === FieldType.RelationMany) {
                    relationAttr[v] = attr
                    console.log("FIND relationMany in enable attrs", attr)
                }
                if (attr?.type === FieldType.RelationOneData || attr?.type === FieldType.RelationOneID) {
                    relationAttr[v] = attr
                    console.log("FIND relationOne in enable attrs", attr)
                }
            })
            props?.onRelationTreeChange(relationAttr)
        }
    }

    const sortByField = (field: string) => {
        if (props.onSortChange) {
            if (field === props.sortBy) {
                props.onSortChange("-" + field)    //flip to DESC
            } else if ("-" + field === props.sortBy) {
                props.onSortChange("")   //clear search if -
            } else {
                props.onSortChange(field)   //just set sort
            }
        }
    }

    const searchByField = (field: string, value: string) => {
        //let params = new URLSearchParams(props.searchQuery);
        //params.set(field, value);
        //props.onSearch(params.toString())

        //let paramsJson = JSON.parse(props.searchQuery) ?? {};
        let paramsJson: any = props.searchQuery ?? {}
        paramsJson[field] = value;
        props.onSearch(paramsJson);
    }

    return (
        <div className="table-responsive">
            <table className="table table-sm table-hover table-striped">
                <thead>
                {
                    (props.data !== undefined) &&
                    <TableViewHeader
                        enabledAttributes={enabledAttributes}
                        model={props.model}
                        sortBy={props.sortBy}
                        sortByField={sortByField}
                        toggleAttrShow={toggleAttrShow}/>
                }
                {
                    (props.onSearch != null && props.data !== undefined) &&
                    <TableViewSearch
                        enabledAttributes={enabledAttributes}
                        model={props.model}
                        sortBy={props.sortBy}
                        searchByField={searchByField}
                        toggleAttrShow={toggleAttrShow}
                        onCreateClick={props.onCreateClick}/>
                }

                </thead>
                <tbody>
                <TableViewRows
                    model={props.model}
                    enabledAttributes={enabledAttributes}
                    data={props.data}
                    searchByField={searchByField}
                    onCreateClick={props.onCreateClick}
                    onRowClick={props.onRowClick}/>
                </tbody>
            </table>
            {
                (props.pagination?.data != null) &&
                <Pagination data={props.pagination.data} callback={props.pagination.callback}/>
            }
        </div>
    )
}