import React, {useCallback} from 'react';
import BreadCrumbs from "../../adminLTE/components/navigation/BreadCrumbs";
import UserDropdown from "./components/UserDropdown";
import {observer} from "mobx-react-lite";
import {uiStore} from "../../store/UiStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Button, ButtonGroup, NavItem} from "react-bootstrap";
import {API_SERVERS, Constants} from "../../utils/constants";
import WssHeader from "../../modules/wss/header";

const Header = observer(() => {
    const navbarVariant = uiStore.navbarVariant
    const headerBorder = uiStore.privateHeaderBorder

    const [isFullscreen, setIsFullscreen] = React.useState(false);
    React.useEffect(() => {
        function onFullscreenChange() {
            setIsFullscreen(Boolean(document.fullscreenElement));
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);

        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);


    const handleToggleMenuSidebar = () => {
        uiStore.toggleMenuSidebarCollapsed()
    };


    const getContainerClasses = useCallback(() => {
        let classes = `navbar navbar-expand ${navbarVariant}`;
        if (headerBorder) {
            classes = `${classes} border-bottom-0`;
        }
        return classes;
    }, [navbarVariant, headerBorder]);

    return (
        <nav className={"main-header " + getContainerClasses()}>
            <ul className="navbar-nav">
                <NavItem>
                    <button onClick={handleToggleMenuSidebar} className="btn nav-link">
                        <FontAwesomeIcon icon={solid("bars")}/>
                    </button>
                </NavItem>
                {
                    /* <li className="nav-item d-none d-sm-inline-block">
                         <Link to="/" className="nav-link">
                             {('header.label.home')}
                         </Link>
                     </li>
                     <li className="nav-item d-none d-sm-inline-block">
                     <Link to="/" className="nav-link">
                           {('header.label.contact')}
                     </Link>
                     </li>*/
                }
            </ul>

            <ul className="navbar-nav">
                <NavItem>
                    <BreadCrumbs/>
                </NavItem>
            </ul>

            {
                (uiStore.screenSize === 'lg') &&
                <NavItem className="pl-5">
                    <ButtonGroup aria-label="Basic example" className="btn-group-sm">
                        <Button variant="dark">API:</Button>
                        <Button variant={Constants.IS_LOCALHOST ? "primary" : "secondary"}
                                onClick={() => Constants.SET_API_SERVER(API_SERVERS[0])}>
                            <small>local</small>
                        </Button>
                        <Button variant={!Constants.IS_LOCALHOST ? "primary" : "secondary"}
                                onClick={() => Constants.SET_API_SERVER(API_SERVERS[1])}>
                            <small>prod</small></Button>
                    </ButtonGroup>
                </NavItem>
            }


            <NavItem className="pl-5">
                <WssHeader/>
            </NavItem>

            <ul className="navbar-nav ml-auto">
                <NavItem>
                    <button
                        onClick={() =>
                            isFullscreen
                                ? document.exitFullscreen() : document.body.requestFullscreen()}
                        className="btn nav-link" data-widget="fullscreen">
                        <FontAwesomeIcon
                            icon={
                                isFullscreen
                                    ? solid("compress-arrows-alt")
                                    : solid("expand-arrows-alt")
                            }/>
                    </button>
                </NavItem>

                <NavItem>
                    <UserDropdown/>
                </NavItem>

            </ul>
        </nav>
    );
});

export default Header;
