import React, {useEffect, useState} from 'react';
import {Invoices} from "../../../api/kassa/kassa";
import {toast} from "react-toastify";
import {UserInvoice} from "../../../models/users/UserInvoice";
import {InvoiceModal} from "../modals/InvoiceModal";
//import {faInfoCircle} from "@fortawesome/free-regular-svg-icons";
import TableIndex from "../../../crud/TableIndex/TableIndex";
import {UserInvoicesModel} from "../../user-invoices/_model";
import {Pagination} from "../../../crud/types/Pagination";

export const InvoicesTable = () => {
    const [userInvoices, setUserInvoices] = useState<Array<UserInvoice>>([]);
    const [currentInvoice, setCurrentInvoice] = useState<UserInvoice | null>(null);
    const [pagination, setPagination] = useState<Pagination>(new Pagination());

    const getData = () => {
        const treeJson = {
            relation: {
                tarif: [],
                user: {
                    //fields: ['username', 'id', 'barcode', 'fio', 'balance'],
                    fields: ['image_name', 'id', 'barcode', 'name', 'balance'],
                    relation: {'roles': []}
                }
            }
        }

        Invoices(treeJson)
            .then((resp: any) => {
                setUserInvoices(resp.data)
                setPagination(resp.pagination)
            })
            //.finally(() => setDataLoaded(true))
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }
    useEffect(() => getData(), [])


    return (
        <div className="table-responsive-sm">
            <InvoiceModal onClose={() => setCurrentInvoice(null)} invoice={currentInvoice}/>
            <TableIndex
                model={UserInvoicesModel}
                data={userInvoices}
                enabledAttributes={UserInvoicesModel.defaultShowAttrs}
                showSettings={true}
                pagination={{
                    data: pagination,
                    callback: setPagination
                }}
                onRowClick={(invoice) => setCurrentInvoice(invoice)}
                sortBy="-id"
                //onSortChange={this.onSortChange}
                //searchQuery={this.state.searchString}
                //onSearch={this.onSearch}
                onRelationTreeChange={() => {
                }}
            />
        </div>
    )
}
