import React from "react";
import {BaseModelInfo} from "../../types/BaseModelInfo";
import BaseCreatePage from "../BaseCreatePage";

type Props = {
    modelInfo: BaseModelInfo;
    form: any;
}

export class SpaCrudCreate extends BaseCreatePage {
    constructor(props: Props) {
        super(props);
        super.model = props.modelInfo;
        super.FormComponent = props.form;
        super.relationTree = props.modelInfo.relationTree
    }
}