/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TelegrambotUserTriggersModel: BaseModelInfo = {
    info: {
        name: "telegrambot-user-triggers",
        label: "Telegrambot user-triggers",
        apiPath: "/telegrambot/api/telegrambot-user-triggers",
        viewPath: "/telegrambot/telegrambot-user-triggers"
    },
    attrs: {
        id: {label: "ID"},
        user_id: {label: "Пользователь"},
        is_automatic: {label: "Флаг автоматического назначения"},
        source: {label: "Источник записи"},
        name: {label: "Название триггера"},
        value: {label: "Значение триггера"},
        created_at: {label: "Дата создания"},
        updated_at: {label: "Дата обновления"},
        users: {label: '"Users"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {},
    defaultShowAttrs: ["user_id", "is_automatic", "source", "name", "value",]
};


