/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TelegrambotUserTriggersCreate from './create';
import TelegrambotUserTriggersUpdate from './update';
import TelegrambotUserTriggersView from './view';
import TelegrambotUserTriggersIndex from './index';
import {TelegrambotUserTriggersModel} from "./_model";

const TelegrambotUserTriggersRoutes = [
    <>
        <Route path={TelegrambotUserTriggersModel.info.viewPath} element={<TelegrambotUserTriggersIndex/>}/>
        <Route path={TelegrambotUserTriggersModel.info.viewPath + '/create'}
               element={<TelegrambotUserTriggersCreate/>}/>
        <Route path={TelegrambotUserTriggersModel.info.viewPath + '/update/:id'}
               element={<TelegrambotUserTriggersUpdate/>}/>
        <Route path={TelegrambotUserTriggersModel.info.viewPath + '/view/:id'}
               element={<TelegrambotUserTriggersView/>}/>
    </>
];
export default TelegrambotUserTriggersRoutes;
