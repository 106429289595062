/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import TableGamesCoopsForm from "./_form";
import {TableGamesCoopsModel} from "./_model";


class TableGamesCoopsCreate extends BaseCreatePage {
    //public model = TableGamesCoopsModel;
    //public FormComponent = TableGamesCoopsForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesCoopsModel;
        super.FormComponent = TableGamesCoopsForm;
        super.relationTree = TableGamesCoopsModel.relationTree
    }
}

export default TableGamesCoopsCreate;