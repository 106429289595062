import React, {useState} from 'react'
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faCircle,
    faCog,
    faEllipsis,
    faInfoCircle,
    faTableList
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PaginationCallback} from "../../../crud/types/Pagination";
import {Dropdown, Form, InputGroup, OverlayTrigger, Popover, Row} from "react-bootstrap";

export default function (props: PaginationCallback) {

    //const [pageSizeInput, setPageSizeInput] = useState(props.data.pageSize)
    const [isDotsShowed, setIsDotsShowed] = useState(false)
    const cells = []

    if (!isDotsShowed) {
        for (let i = 1; i <= props.data.pageCount; i++) {
            cells.push(i)
        }
    } else {
        cells.push(1)
        cells.push(2)
        for (
            let i = props.data.page - 2;
            i <= props.data.page + 2;
            i++
        ) {
            if (i > 0 && i <= props.data.pageCount) {
                cells.push(i)
            }
        }

        cells.push(props.data.pageCount - 1)
        cells.push(props.data.pageCount)
    }

    const pageEls = []

    for (let i = 1; i <= props.data.pageCount; i++) {
        let active = (i === props.data.page)

        if (cells.includes(i)) {
            pageEls.push(
                <li className={`page-item ${active && "active"}`} key={i}>
                    <button className={`page-link  btn-sm ${active && "active"}`}
                            onClick={() => props.callback({...props.data, page: i})}
                    >
                        {
                            (active)
                                ? <>{i} / <small>{props.data.pageCount}</small></>
                                : <>{i}</>
                        }
                    </button>
                </li>
            )
        } else {
            if (isDotsShowed) {
                pageEls.push(
                    <li className='page-item btn-sm' key={i}>
                        <FontAwesomeIcon icon={faCircle} size="sm"
                                         style={{padding: "0.62rem  0px", border: "1px solid #dee2e6"}}/>
                    </li>
                )
            }
        }
    }

    const popover = (
        <Popover id="popover-pages">
            <Popover.Title as="h3">Состояние страницы</Popover.Title>
            <Popover.Content>
                <table>
                    <tbody>
                    <tr>
                        <td>Страница №</td>
                        <td>{props.data.page}</td>
                    </tr>
                    <tr>
                        <td>Всего страниц</td>
                        <td>{props.data.pageCount}</td>
                    </tr>
                    <tr>
                        <td>Размер страницы</td>
                        <td>{props.data.pageSize}</td>
                    </tr>
                    <tr>
                        <td>Всего элементов</td>
                        <td>{props.data.totalItems}</td>
                    </tr>
                    </tbody>
                </table>
            </Popover.Content>
        </Popover>
    );

    return (
        <Row className="justify-content-center">
            <ul className='pagination'>
                <li className='page-item ' key='first'>
                    <button
                        disabled={props.data.page - 1 < 1}
                        className='page-link btn-sm'
                        onClick={() => props.callback({...props.data, page: 1})}
                        aria-label='First'>
                        <FontAwesomeIcon icon={faAngleDoubleLeft}/>
                    </button>
                </li>
                <li className='page-item' key='prev'>
                    <button
                        disabled={props.data.page - 1 < 1}
                        className='page-link btn-sm'
                        onClick={() => props.callback({...props.data, page: (props.data.page - 1)})}
                        aria-label='Previous'>
                        <FontAwesomeIcon icon={faAngleLeft}/>
                    </button>
                </li>
                {pageEls}
                <li className='page-item' key='next'>
                    <button
                        disabled={props.data.page + 1 > props.data.pageCount}
                        className='page-link btn-sm'
                        onClick={() => props.callback({...props.data, page: (props.data.page + 1)})}
                        aria-label='Next'>
                        <FontAwesomeIcon icon={faAngleRight}/>
                    </button>

                </li>
                <li className='page-item' key='last'>
                    <button
                        disabled={props.data.page + 1 > props.data.pageCount}
                        className='page-link btn-sm'
                        onClick={() => props.callback({...props.data, page: (props.data.pageCount)})}
                        aria-label='Last'>
                        <FontAwesomeIcon icon={faAngleDoubleRight}/>
                    </button>
                </li>
            </ul>
            <OverlayTrigger
                /*trigger="click" */
                placement="top"
                overlay={popover}
                defaultShow={false}
                delay={100}>
                <FontAwesomeIcon size={"2x"} className="pl-1" icon={faInfoCircle}/>
            </OverlayTrigger>

            <ul className='d-flex justify-content-right' style={{listStyle: "none"}}>
                <li className="flex">
                    <Dropdown title={"settings"} className="dropdown-menu-sm-left">
                        <Dropdown.Toggle variant="dark" className="btn-sm">
                            <FontAwesomeIcon icon={faCog}/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="super-colors">
                            <Dropdown.ItemText key="shAttr">
                                <Form.Check
                                    type="switch"
                                    id="shAttr"
                                    onChange={() => setIsDotsShowed(!isDotsShowed)}
                                    label={<small>Свернуть в <FontAwesomeIcon icon={faEllipsis}/></small>}
                                    checked={isDotsShowed}/>
                            </Dropdown.ItemText>
                            <Dropdown.ItemText key="pageSize">
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">
                                        <FontAwesomeIcon icon={faTableList}/>
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Page Size"
                                        aria-label="Page Size"
                                        /*  onSubmit={() => props.callback({
                                              ...props.data,
                                              pageSize: pageSizeInput
                                              //parseInt(event?.target?.value ?? 12)
                                          })}*/
                                        onChange={(event) => props.callback({
                                            ...props.data,
                                            pageSize: parseInt(event.target.value)
                                        })}
                                        //onChange={(event) => setPageSizeInput(parseInt(event.target.value))}
                                        value={props.data.pageSize}
                                        //value={pageSizeInput}
                                        aria-describedby="basic-addon1"/>
                                </InputGroup>
                            </Dropdown.ItemText>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
            </ul>
        </Row>
    )
}
