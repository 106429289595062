/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import TelegrambotUserTriggersForm from "./_form";
import {TelegrambotUserTriggersModel} from "./_model";

class TelegrambotUserTriggersUpdate extends BaseUpdatePage {
    //public model = TelegrambotUserTriggersModel;
    //public FormComponent = TelegrambotUserTriggersForm;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotUserTriggersModel;
        super.FormComponent = TelegrambotUserTriggersForm;
        super.relationTree = TelegrambotUserTriggersModel.relationTree
    }
}

export default withTranslation()(withRouter(TelegrambotUserTriggersUpdate));
