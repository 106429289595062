/**
 * Generated by ReactYii 3.0.27 at 07.05.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField} from "crud";
import BaseForm from "crud/base/BaseForm";
import RelationOneDropDown from "crud/FormInputs/RelationOneDropDown";
import {TagsModel} from "./_model";

class TagsForm extends BaseForm {
    //public model = TagsModel;

    constructor(props: any) {
        super(props);
        super.model = TagsModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                name: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" +  this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>
                    <InputField name={'id'}
                                model={this.model.info.name}
                                label={this.model.attrs.id.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.id}/>

                    <InputField name={'name'}
                                model={this.model.info.name}
                                label={this.model.attrs.name.label}
                                onChange={this.handleInputChange}
                                value={this.state.form.name}/>


                    <RelationOneDropDown name={'wikiToTags_id'}
                            model={this.model.info.name}
                            label={this.model.attrs?.wikiToTags_id?.label}
                            relationUrl={this.model.attrs.wikiToTags.params.model?.info?.apiPath + "/index"}
                            onChange={(val: any) => this.handleInputChange({name: "wikiToTags_id", value: val.id})}
                            value={this.state.form['wikiToTags_id']}/>

        
                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default TagsForm;
