/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import TelegrambotMessagesForm from "./_form";
import {TelegrambotMessagesModel} from "./_model";


class TelegrambotMessagesCreate extends BaseCreatePage {
    //public model = TelegrambotMessagesModel;
    //public FormComponent = TelegrambotMessagesForm;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotMessagesModel;
        super.FormComponent = TelegrambotMessagesForm;
        super.relationTree = TelegrambotMessagesModel.relationTree
    }
}

export default TelegrambotMessagesCreate;