import React, {useEffect, useState} from "react";
import EventPanel from "./eventPanel";
import EventTitle from "./eventTitle";
import {Constants} from "../../../../utils/constants";

type Props = {
    data:any
}
const EventView = (props:Props) => {


    // @ts-ignore
    //const [data, setData] = useState<EventModel>([]);
    //const [data, setData] = useState<Product>();




    return (
        <div className="h-100">
            <div className={"event-cover"}
                    style={{'backgroundImage': `url("` + Constants.publicImageViewPath +"events/"+ props.data?.image_name + `")`}}>

                <div className="event-title-container">
                    <EventTitle name={props.data.name} eventType={props.data.eventType} from_dt={props.data.from_dt}/>

                </div>
                <div className="event-panel-container">
                    <EventPanel {...props.data} />
                </div>

                <div className="header-quote text-center align-items-center">

                    {/* {this.renderQuoteAuthor()}*/}
                </div>

            </div>


            <div className="container">
                <div className="card p-3">
                    <div className="event" itemScope itemType="http://schema.org/Event">
                        <div className="event-content-container">
                            <div className="event-content">
                                <div itemProp="description"
                                     dangerouslySetInnerHTML={{__html: props.data?.description ?? ''}}/>
                                {/*      <Gratitude links={['zovem.ru', 'tandp.ru', '2do2go.ru', '2anticafe.com']}/>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default EventView;