/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import TelegrambotFaqForm from "./_form";
import {TelegrambotFaqModel} from "./_model";


class TelegrambotFaqCreate extends BaseCreatePage {
    //public model = TelegrambotFaqModel;
    //public FormComponent = TelegrambotFaqForm;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotFaqModel;
        super.FormComponent = TelegrambotFaqForm;
        super.relationTree = TelegrambotFaqModel.relationTree
    }
}

export default TelegrambotFaqCreate;