/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TelegrambotModulesCreate from './create';
import TelegrambotModulesUpdate from './update';
import TelegrambotModulesView from './view';
import TelegrambotModulesIndex from './index';
import {TelegrambotModulesModel} from "./_model";

const TelegrambotModulesRoutes = [
    <>
        <Route path={TelegrambotModulesModel.info.viewPath} element={<TelegrambotModulesIndex/>}/>
        <Route path={TelegrambotModulesModel.info.viewPath + '/create'} element={<TelegrambotModulesCreate/>}/>
        <Route path={TelegrambotModulesModel.info.viewPath + '/update/:id'} element={<TelegrambotModulesUpdate/>}/>
        <Route path={TelegrambotModulesModel.info.viewPath + '/view/:id'} element={<TelegrambotModulesView/>}/>
    </>
];
export default TelegrambotModulesRoutes;
