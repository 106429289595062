import React, {Component} from "react";
import QRCode from "react-qr-code";
import {Col, Row} from "react-bootstrap";

type Props = {
    value: any
    displayValue?: boolean
}

class BarCodeViewer extends Component<Props> {

    /*    width: 2,
        height: 100,
        format: "CODE128",
        displayValue: true,
        fontOptions: "",
        font: "monospace",
        textAlign: "center",
        textPosition: "bottom",
        textMargin: 2,
        fontSize: 20,
        background: "#ffffff",
        lineColor: "#000000",
        margin: 10,
        marginTop: undefined,
        marginBottom: undefined,
        marginLeft: undefined,
        marginRight: undefined*/

    render() {
        // console.log(this.props.value)
        return (
            <Row>
                {/* <Barcode value={this.props.value}
                         format="CODE128"
                         displayValue={false}
                         width={1}
                         height={20}
                         fontSize={10}
                />*/}
                <Col>
                    <QRCode
                        size={32}
                        level="H"
                        style={{height: "auto", maxWidth: "50px", width: "50px"}}
                        value={this.props.value}/>
                </Col>
                <Col>
                    {this.props.value}
                </Col>

            </Row>

        )
    }
}

export default BarCodeViewer