/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import TableGamesThemesForm from "./_form";
import {TableGamesThemesModel} from "./_model";


class TableGamesThemesCreate extends BaseCreatePage {
    //public model = TableGamesThemesModel;
    //public FormComponent = TableGamesThemesForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesThemesModel;
        super.FormComponent = TableGamesThemesForm;
        super.relationTree = TableGamesThemesModel.relationTree
    }
}

export default TableGamesThemesCreate;