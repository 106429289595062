import React from 'react';
import {Link} from 'react-router-dom';
import {User} from "../../../models/users/User";
import {Card} from "react-bootstrap";
import DataTable from "../../../crud/data_viewers/DataTable";
import {UsersModel} from "../../users/_model";

type Props = {
    profile?: User
}

const InfoTab = (props: Props) => {
    return (
        <div>
            <Card className="card-success">

                <Card.Body>
                    <div className="float-right">
                        <Link className="btn btn-danger"
                              to={UsersModel.info.viewPath + '/update/' + props?.profile?.id}>
                            Прямое редактирование
                        </Link>
                    </div>
                    <DataTable
                        data={props.profile}
                        model={UsersModel}
                        disabledFields={["id",
                            "role_id", "" +
                            "userCheckpoints",
                            "userImages",
                            "userAuthLogins",
                            "userAuthEmails",
                            "is_dead_soul",
                            "userAuthTelegrams"]
                        }
                    />
                </Card.Body>
            </Card>
        </div>
    );
};

export default InfoTab;
