import SwaggerUI from "swagger-ui-react"
import "../../css/swagger/main.scss"
import {Constants} from "../../utils/constants";
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import {authStore} from "../../store/AuthStore";
import {useParams} from "react-router-dom";
import {Translation} from "react-i18next";
import React from "react";


// TODO: reverse filter
// const filteredOperations = operations.filter(operation => {
//   const tags = operation.get("tags");
//   if (!tags) return true;
//   const excludedTags = this.state.excludedTags;
//   for (let i = 0; i < tags.size; i++) {
//     if (excludedTags.includes(tags.get(i))) {
//       return false;
//     }
//   }
//   return true;
// });

export default function SwaggerPage() {

    breadcrumbsStore.items = [
        {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
        {name: "Swagger"},
    ]


    /*    function onComplete() {
            //const ui = window!.ui;
            // ui.updatePlugin('excludeFilterPlugin', (plugin:any) => plugin.specActions.updateSpec(ui.specSelectors.specJson()));
        }*/


    let {filter} = useParams();
    let authStr = "{\"Authorization\":{\"name\":\"Authorization\",\"schema\":{\"type\":\"apiKey\",\"name\":\"Authorization\",\"in\":\"header\",\"scheme\":\"Bearer\"},\"value\":\"Bearer " + authStore.getAccessToken() + "\"}}"
    //SET AUTH
    localStorage.setItem("authorized", authStr);
    return <SwaggerUI
        url={Constants.API_FULL_URL + "/open-api"}
        displayRequestDuration={true}
        tryItOutEnabled={true}
        /*onComplete={onComplete}
        plugins={[excludeFilterPlugin]}*/
        persistAuthorization={true}
        docExpansion='none'
        filter={filter}
    />
}