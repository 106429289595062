import React, {useEffect, useState} from 'react'
import {classPostfix, validate} from '../../api/yiiValidation'
import {FieldInputProps} from "../../types/FieldInputProps";
import {InputGroup} from "react-bootstrap";
import useWebSocket from "react-use-websocket";
import {Constants} from "../../../utils/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";


export default function BarcodeInputField(props: FieldInputProps) {
    const [helpBlock, setHelpBlock] = useState("");
    const [validated, setValidated] = useState<boolean | undefined>();
    //TODO now ignore props.validated

    const {sendMessage, lastMessage, readyState} = useWebSocket(Constants.WSS_URL,
        {
            shouldReconnect: (closeEvent) => true,
            reconnectAttempts: 10,
            reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
            //reconnectInterval: 3000,
        }
    );

    useEffect(() => {
        console.log("lastMessage", lastMessage)
        if (lastMessage !== null) {
            let wsResp = JSON.parse(lastMessage?.data);
            let barcode = wsResp.data.barcode //.replace("=","")
            //toast.success("#" + wsResp.barcode.slice(-3));
            console.log(barcode)
            props.onChange(barcode)

        }
    }, [lastMessage]);


    const onChangeValidate = (event: any) => {
        props.onChange(event.target.value)

        if (props.model !== undefined && props.model !== '') {
            //todo validate exist cards
            /*validate(
                props.model,
                props.name,
                event.target.value,
                () => {
                    setHelpBlock("")
                    setValidated(true)
                },
                (err: any) => {
                    setHelpBlock(err)
                    setValidated(false)
                }).then();*/
        }
    }

    let required = (props.pluginProps !== undefined) ? (props.pluginProps.hasOwnProperty("required") ? (props.pluginProps.required === true) ? 'required' : '' : '') : '';

    return (
        <InputGroup
            className={'form-group field-' + props.model + '-' + props.name + ' ' + props.class}>
            <InputGroup.Prepend>
                <InputGroup.Text className={'control-label ' + required}>{props.label}</InputGroup.Text>
            </InputGroup.Prepend>
            <input
                id={props.model + '-' + props.name}
                className={'form-control ' + classPostfix(validated)}
                {...props.pluginProps}
                name={props.name}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value || ''}
                onChange={onChangeValidate}
            />
            <InputGroup.Append>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={solid('credit-card')}/>
                    {
                        (validated !== undefined && !validated) &&
                        (props.validated !== undefined) ? props.helpBlock : helpBlock
                    }
                </InputGroup.Text>
            </InputGroup.Append>

        </InputGroup>
    )
}

