import moment from "moment";
import BoolViewer from "../../../../crud/field_viewers/BoolViewer";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {daysOfWeek} from "./daysOfWeek";
import EventCalendarsForm from "../../event-calendars/_form";
import Modal from "react-bootstrap/Modal";
import {BaseDeleteModal} from "../../../../crud/dialogs/BaseDeleteModal";
import {DeleteModel} from "../../../../crud/api/crudApi";
import {toast} from "react-toastify";

type Props = {
    data: any
    eventId?: any
    onSubmit?: any
}
type EventCalendar = {
    id: number,
    event_id: number,
    day_of_week: number,
    repeatable: boolean,
    from_dt: any,
    to_dt: any,
}
export default function EventSchedule(props: Props) {
    const [editEvent, setEditEvent] = useState<EventCalendar | null>(null);
    const [create, setCreate] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState(0);

    // {id: 1, event_id: 1, day_of_week: 0, repeatable: 0, from_dt: '2022-01-01 10:00:00', …}
    const rows = props.data.eventCalendars.map((event: EventCalendar, i: number) => {
            console.log(event)
            return <tr key={i}>
                <td>{daysOfWeek[event?.day_of_week]}</td>
                <td>{moment(event.from_dt).format('HH:mm')}</td>
                <td>{moment(event.to_dt).format('HH:mm')}</td>
                <td><BoolViewer value={event.repeatable}/></td>
                <td>
                    <Button className="btn-sm" onClick={() => setEditEvent(event)}>
                        <FontAwesomeIcon icon={faEdit}/>
                    </Button>
                    <Button className="btn-sm btn-danger ml-1" onClick={() => setDeleteId(event.id)}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </Button>
                </td>
            </tr>;
        }
    )
    /** day_of_week
     event_id
     from_dt

     "2022-01-01 20:00:00"
     id
     repeatable
     to_dt
     */

    return <>
        <Modal size={"lg"} show={(editEvent !== null)} onHide={() => setEditEvent(null)}>
            <Modal.Body>
                {
                    ((editEvent?.id ?? 0) >> 0) &&
                    <EventCalendarsForm
                        //@ts-ignore
                        onSubmit={() => {
                            setEditEvent(null);
                            props.onSubmit()
                        }
                        }
                        data={editEvent}
                        action={"/rapi/event-calendars/update?id=" + editEvent?.id}
                        //@ts-ignore
                        id={(editEvent?.id ?? 0)}
                        //@ts-ignore
                        eventId={(props?.data?.eventCalendars?.event_id) ?? 0}

                    />
                }

            </Modal.Body>
        </Modal>

        <Modal size={"lg"} show={(create)} onHide={() => setCreate(false)}>
            <Modal.Body>
                <EventCalendarsForm
                    //@ts-ignore
                    eventId={props?.eventId}
                    action={"/rapi/event-calendars/create"}
                    //@ts-ignore
                    onSubmit={() => {
                        setEditEvent(null);
                        props.onSubmit()
                    }
                    }
                />
            </Modal.Body>
        </Modal>


        <BaseDeleteModal
            show={(deleteId > 0)}
            onDelete={() => {
                console.log("remove" ,deleteId )
                //https://zdbot.zdver.com//rapi/event-calendars/delete?id=260

                DeleteModel("/rapi/event-calendars", deleteId)
                    .then((resp: any) => {
                        console.log(resp)
                        props.onSubmit();
                        setDeleteId(0);
                        toast.success("Запись удалена")
                    })
                    .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));

            }
            }
            onClose={() => setDeleteId(0)}/>

        <table className="table w-100">
            <tbody>
            <tr>
                <th>День недели</th>
                <th>C</th>
                <th>До</th>
                <th>Повторять?</th>
                <th>
                    <button
                        className="float-right btn btn-sm btn-outline-success"
                        onClick={() => setCreate(true)}>
                        <FontAwesomeIcon icon={faPlus}/>
                    </button>

                </th>
            </tr>
            </tbody>
            <tbody>
            {rows}
            </tbody>
        </table>
    </>
}