import React from 'react';
import {Card, Modal} from "react-bootstrap";
import {CheckoutType, KassaCheckout} from "../../../api/kassa/kassa";
import {toast} from "react-toastify";
import {UserInvoice} from "../../../models/users/UserInvoice";
import {getPaymentType} from "../helpers";
import MomentViewer from "../../../crud/field_viewers/MomentViewer";
import {UsersModel} from "../../users/_model";
import {useNavigate} from "react-router-dom";

type Props = {
    onClose: any,
    invoice: UserInvoice | null,
}

export const InvoiceModal = (props: Props) => {
    const navigate = useNavigate();

    const onClose = () => {
        props.onClose();
    }

    const checkout = (id: number, type: keyof typeof CheckoutType) => {
        KassaCheckout(id, type)
            .then((resp: any) => {
                console.log(resp)
                //setCurrentUsers(resp.data);
                onClose()
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));
    }

    return <Modal className="modal-warning" show={(props.invoice != null)} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Чек №{props.invoice?.id} от {props.invoice?.created_at}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Card>
                <Card.Body>
                    <table className="table">
                        <tbody>
                        <tr>
                            <td>Карта</td>
                            <td>{props.invoice?.user?.barcode}</td>
                        </tr>
                        <tr>
                            <td>Имя</td>
                            <td>
                                <button type="button"
                                        onClick={() => navigate(UsersModel.info.viewPath
                                            + "/view/"
                                            + props.invoice?.user?.id
                                        )}
                                        className="btn btn-outline-info btn-sm"
                                >

                                    {props.invoice?.user?.name}
                                </button>
                            </td>
                        </tr>
                        {/*  <tr>
                            <td>Роль</td>
                            <td>{props.invoice?.user?.role?.name}</td>
                        </tr>*/}
                        <tr>
                            <td>Тариф</td>
                            <td>{props.invoice?.tarif?.name}</td>
                        </tr>
                        <tr>
                            <td>Тип оплаты</td>
                            <td>{getPaymentType(props?.invoice?.payment_type)}</td>
                        </tr>

                        <tr>
                            <td>Время прихода</td>
                            <td><MomentViewer value={props.invoice?.created_at}/></td>
                        </tr>
                        <tr>
                            <td>Времени провел</td>
                            <td>{props.invoice?.minutes} мин</td>
                        </tr>
                        <tr>
                            <td>Итого</td>

                            <td>
                                {props.invoice?.sum}₽
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">

        </Modal.Footer>
    </Modal>
}
