/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {Navigate} from "react-router-dom";
import {Translation} from "react-i18next";
import {InputField, SwitchField, WysiwygEditor} from "crud";
import BaseForm from "crud/base/BaseForm";
import {TableGamesModel} from "./_model";
import DndImage from "../../../crud/FormInputs/files/DndImage";
import {Col, Row} from "react-bootstrap";

class TableGamesForm extends BaseForm {
    //public model = TableGamesModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesModel;
        this.state = {
            id: '',
            validation: '',
            redirect: false,
            form: {
                id: '',
                name: '',
                image_name: '',
                name_rus: '',
                game_rules: '',
                descr: '',
                age: '',
                short_descr: '',
                bgg_id: '',
                bgg_rate_average: '',
                complexity: '',
                is_active: '',
                players_from: '',
                players_to: '',
                duration: '',
                shelf_num: '',
                price_full: '',
                price_day: '',
                cnt: '',
                available_for_sale: '',
                available_for_rent: '',
            },
        };

    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.model.info.viewPath + "/view/" + this.state.id}/>;
        }
        return (
            <form onSubmit={this.handleSubmit}>
                <div className={"flex-form"}>

                    <InputField name={'name'}
                                model={this.model.info.name}
                                label={this.model.attrs.name.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['name']}/>

                    <InputField name={'name_rus'}
                                model={this.model.info.name}
                                label={this.model.attrs.name_rus.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['name_rus']}/>


                    <InputField name={'short_descr'}
                                model={this.model.info.name}
                                label={this.model.attrs.short_descr.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['short_descr']}/>

                    <DndImage name={'image_name'}
                              modelId={this.state.id}
                              model={this.model.info.name}
                              label={this.model.attrs.image_name.label}
                              attr={this.model.attrs.image_name}
                              onChange={this.handleInputChange}
                              value={this.state.form['image_name']}/>

                    <WysiwygEditor name={'descr'}
                                   model={this.model.info.name}
                                   label={'Описание'}
                                   //onChange={this.handleInputChange}
                                   onChange={(value: any) => this.handleInputChange({
                                       name: "descr",
                                       value: value
                                   })}
                                   value={this.state.form['descr']}/>


                    <InputField name={'age'}
                                model={this.model.info.name}
                                label={this.model.attrs.age.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['age']}/>

                    <Row>
                        <Col>
                            <InputField name={'bgg_id'}
                                        model={this.model.info.name}
                                        label={this.model.attrs.bgg_id.label}
                                        onChange={this.handleInputChange}
                                        value={this.state.form['bgg_id']}/>
                        </Col>
                        <Col>
                            <InputField name={'bgg_rate_average'}
                                        model={this.model.info.name}
                                        label={this.model.attrs.bgg_rate_average.label}
                                        onChange={this.handleInputChange}
                                        value={this.state.form['bgg_rate_average']}/>
                        </Col>
                    </Row>
                    <InputField name={'game_rules'}
                                model={this.model.info.name}
                                label={this.model.attrs.game_rules.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['game_rules']}/>


                    <InputField name={'complexity'}
                                model={this.model.info.name}
                                label={this.model.attrs.complexity.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['complexity']}/>

                    <Row>
                        <Col>
                            <InputField name={'players_from'}
                                        model={this.model.info.name}
                                        label={this.model.attrs.players_from.label}
                                        onChange={this.handleInputChange}
                                        value={this.state.form['players_from']}/>
                        </Col>
                        <Col>
                            <InputField name={'players_to'}
                                        model={this.model.info.name}
                                        label={this.model.attrs.players_to.label}
                                        onChange={this.handleInputChange}
                                        value={this.state.form['players_to']}/>
                        </Col>
                    </Row>


                    <Row className="row-cols-3">
                        <Col>
                            <SwitchField name={'is_active'}
                                         model={this.model.info.name}
                                         label={'доступно в заведении'}
                                         onChange={this.handleInputChange}
                                         value={this.state.form['is_active']}/>
                        </Col>
                        <Col>
                            <SwitchField name={'available_for_sale'}
                                         model={this.model.info.name}
                                         label={'доступность для продажи'}
                                         onChange={this.handleInputChange}
                                         value={this.state.form['available_for_sale']}
                                         class="p-2"/>
                        </Col>
                        <Col>
                            <SwitchField name={'available_for_rent'}
                                         model={this.model.info.name}
                                         label={'доступность для аренды'}
                                         onChange={this.handleInputChange}
                                         value={this.state.form['available_for_rent']}
                                         class="p-2"/>
                        </Col>


                    </Row>


                    <InputField name={'duration'}
                                model={this.model.info.name}
                                label={this.model.attrs.duration.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['duration']}/>

                    <InputField name={'shelf_num'}
                                model={this.model.info.name}
                                label={this.model.attrs.shelf_num.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['shelf_num']}/>

                    <InputField name={'price_full'}
                                model={this.model.info.name}
                                label={this.model.attrs.price_full.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['price_full']}/>

                    <InputField name={'price_day'}
                                model={this.model.info.name}
                                label={this.model.attrs.price_day.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['price_day']}/>

                    <InputField name={'cnt'}
                                model={this.model.info.name}
                                label={this.model.attrs.cnt.label}
                                onChange={this.handleInputChange}
                                value={this.state.form['cnt']}/>


                    {/*


                    <RelationOneDropDown name={'themes_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.themes_id?.label}
                                         relationUrl={this.model.attrs.themes.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "themes_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['themes_id']}/>

                    <RelationOneDropDown name={'types_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.types_id?.label}
                                         relationUrl={this.model.attrs.types.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "types_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['types_id']}/>

                    <RelationOneDropDown name={'coops_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.coops_id?.label}
                                         relationUrl={this.model.attrs.coops.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "coops_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['coops_id']}/>

                    <RelationOneDropDown name={'prices_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.prices_id?.label}
                                         relationUrl={this.model.attrs.prices.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "prices_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['prices_id']}/>

                    <RelationOneDropDown name={'places_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.places_id?.label}
                                         relationUrl={this.model.attrs.places.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "places_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['places_id']}/>

                    <RelationOneDropDown name={'tableGamesThemes_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesThemes_id?.label}
                                         relationUrl={this.model.attrs.tableGamesThemes.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesThemes_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesThemes_id']}/>

                    <RelationOneDropDown name={'tableGamesTypes_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesTypes_id?.label}
                                         relationUrl={this.model.attrs.tableGamesTypes.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesTypes_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesTypes_id']}/>

                    <RelationOneDropDown name={'tableGamesCoops_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesCoops_id?.label}
                                         relationUrl={this.model.attrs.tableGamesCoops.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesCoops_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesCoops_id']}/>

                    <RelationOneDropDown name={'tableGamesPrices_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesPrices_id?.label}
                                         relationUrl={this.model.attrs.tableGamesPrices.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesPrices_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesPrices_id']}/>

                    <RelationOneDropDown name={'tableGamesPlaces_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesPlaces_id?.label}
                                         relationUrl={this.model.attrs.tableGamesPlaces.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesPlaces_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesPlaces_id']}/>

                    <RelationOneDropDown name={'periods_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.periods_id?.label}
                                         relationUrl={this.model.attrs.periods.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "periods_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['periods_id']}/>

                    <RelationOneDropDown name={'parentLinks_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.parentLinks_id?.label}
                                         relationUrl={this.model.attrs.parentLinks.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "parentLinks_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['parentLinks_id']}/>

                    <RelationOneDropDown name={'childrenLinks_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.childrenLinks_id?.label}
                                         relationUrl={this.model.attrs.childrenLinks.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "childrenLinks_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['childrenLinks_id']}/>

                    <RelationOneDropDown name={'childrens_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.childrens_id?.label}
                                         relationUrl={this.model.attrs.childrens.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "childrens_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['childrens_id']}/>

                    <RelationOneDropDown name={'parent_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.parent_id?.label}
                                         relationUrl={this.model.attrs.parents.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "parent_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['parent_id']}/>

                    <RelationOneDropDown name={'bgg_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.bgg_id?.label}
                                         relationUrl={this.model.attrs.bgg.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "bgg_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['bgg_id']}/>

                    <RelationOneDropDown name={'tableGamesImgs_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesImgs_id?.label}
                                         relationUrl={this.model.attrs.tableGamesImgs.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesImgs_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesImgs_id']}/>

                    <RelationOneDropDown name={'tableGamesInUses_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesInUses_id?.label}
                                         relationUrl={this.model.attrs.tableGamesInUses.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesInUses_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesInUses_id']}/>

                    <RelationOneDropDown name={'tableGamesLinks_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesLinks_id?.label}
                                         relationUrl={this.model.attrs.tableGamesLinks.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesLinks_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesLinks_id']}/>

                    <RelationOneDropDown name={'tableGamesLinks0_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesLinks0_id?.label}
                                         relationUrl={this.model.attrs.tableGamesLinks0.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesLinks0_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesLinks0_id']}/>

                    <RelationOneDropDown name={'tableGamesToCoops_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesToCoops_id?.label}
                                         relationUrl={this.model.attrs.tableGamesToCoops.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesToCoops_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesToCoops_id']}/>

                    <RelationOneDropDown name={'tableGamesToPlaces_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesToPlaces_id?.label}
                                         relationUrl={this.model.attrs.tableGamesToPlaces.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesToPlaces_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesToPlaces_id']}/>

                    <RelationOneDropDown name={'tableGamesToPrices_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesToPrices_id?.label}
                                         relationUrl={this.model.attrs.tableGamesToPrices.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesToPrices_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesToPrices_id']}/>

                    <RelationOneDropDown name={'tableGamesToThemes_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesToThemes_id?.label}
                                         relationUrl={this.model.attrs.tableGamesToThemes.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesToThemes_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesToThemes_id']}/>

                    <RelationOneDropDown name={'tableGamesToTypes_id'}
                                         model={this.model.info.name}
                                         label={this.model.attrs?.tableGamesToTypes_id?.label}
                                         relationUrl={this.model.attrs.tableGamesToTypes.params.model?.info?.apiPath + "/index"}
                                         onChange={(val: any) => this.handleInputChange({
                                             name: "tableGamesToTypes_id",
                                             value: val.id
                                         })}
                                         value={this.state.form['tableGamesToTypes_id']}/>
*/}


                    <div className={'form-validation'}>{this.state.validation}</div>
                </div>

                <div className={"text-center"}>
                    <button type={"submit"} className={'btn btn-success'}>
                        <Translation>{(t: any) => t('crud.save')}</Translation>
                    </button>
                </div>
            </form>
        );
    }
}

export default TableGamesForm;
