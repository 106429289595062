/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import RolesForm from "./_form";
import {RolesModel} from "./_model";


class RolesCreate extends BaseCreatePage {
    //public model = RolesModel;
    //public FormComponent = RolesForm;

    constructor(props: any) {
        super(props);
        super.model = RolesModel;
        super.FormComponent = RolesForm;
        super.relationTree = RolesModel.relationTree
    }
}

export default RolesCreate;