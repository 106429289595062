/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import WikiPagesForm from "./_form";
import {WikiPagesModel} from "./_model";


class WikiPagesCreate extends BaseCreatePage {
    //public model = WikiPagesModel;
    //public FormComponent = WikiPagesForm;

    constructor(props: any) {
        super(props);
        super.model = WikiPagesModel;
        super.FormComponent = WikiPagesForm;
        super.relationTree = WikiPagesModel.relationTree
    }
}

export default WikiPagesCreate;