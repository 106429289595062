/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TelegrambotModulesModel} from "./_model";

class TelegrambotModulesIndex extends BaseIndexPage {
    //public model = TelegrambotModulesModel;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotModulesModel;
        super.defaultShowAttrs = TelegrambotModulesModel.defaultShowAttrs;
        super.relationTree = TelegrambotModulesModel.relationTree
    }
}

export default TelegrambotModulesIndex;