import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {ApiRequest, HttpMethod} from "../../../api/api";
import {toast} from "react-toastify";
import stringify from "qs-stringify";

type Props = {
    module_id: any
    data?: any
    onClose: any
}
export default function ConfigsModal(props: Props) {
    const [name, setName] = useState(props?.data?.name);
    const [descr, setDescr] = useState(props?.data?.descr);
    const [val, setVal] = useState(props?.data?.val);

    useEffect(() => {
        setName(props?.data?.name)
        setDescr(props?.data?.descr)
        setVal(props?.data?.val)
    }, [props])

    const onSave = () => {
        const data = {module_id: props.module_id, name, descr, val}
        let url = ""
        if (props.data.isNew) {
            console.log("create command", data)
            url = "/telegrambot/api/telegrambot-module-configs/create"
        } else {
            console.log("update command", data)
            url = "/telegrambot/api/telegrambot-module-configs/update?id=" + props.data.id
        }


        ApiRequest<any>(HttpMethod.POST, url, stringify(data))
            .then((resp: any) => {
                console.log(resp.data)
                toast.success('Saved!')
                props.onClose()
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed save'));
    }
    return (
        <Modal show={(props.data != null)} className="bg-gray-light">
            <Modal.Header>
                <h5 className="modal-title">{(props.data?.isNew) ? "Создание" : "Редактирование"} {name}</h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <form onSubmit={onSave}>
                    <Form.Control id="name" name="name" placeholder="name" value={name} disabled
                                  onChange={(event) => setName(event.target.value)}/>
                    <Form.Control id="descr" name="descr" placeholder="descr" value={descr}
                                  onChange={(event) => setDescr(event.target.value)}/>
                    <Form.Control id="val" name="val" placeholder="Значение" value={val} required={true}
                                  onChange={(event) => setVal(event.target.value)}/>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onClose}>Закрыть</Button>
                <Button variant="success" onClick={onSave}>Сохранить</Button>
            </Modal.Footer>
        </Modal>
    );
}