import {CheckpointUser} from "../../models/users/UserCheckpoint";
import React from "react";
import {UserInvoice} from "../../models/users/UserInvoice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const getPayStatus = (checkpoint: CheckpointUser | UserInvoice) => {
    return (checkpoint?.is_payed)
        ? <span className="bg-success btn-sm">Оплачен</span>
        : <div className="bg-warning btn-sm text-sm">Не оплачен</div>
}

const getCheckpointStatus = (checkpoint?: CheckpointUser) => {
    if (checkpoint?.is_outside) {
        return <span className="bg-dark btn-sm">Выписан</span>
    } else if (checkpoint?.is_inside) {
        return <span className="bg-info btn-sm">Записан</span>
    } else {
        return <span className="bg-info btn-sm">-</span>
    }
}

const getPaymentType = (payment_type: number | undefined) => {
    if (Number.isInteger(payment_type)) {
        if (payment_type === 0) {
            return <span className=" text-success">
                <FontAwesomeIcon icon={solid("money-bill-1")}/> Нал.
            </span>
        } else {
            return <span className=" text-primary">
                <FontAwesomeIcon icon={solid("credit-card")}/> Безнал.
            </span>
        }
    }

}

export {getPayStatus, getCheckpointStatus, getPaymentType}