/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TableGamesModel} from "./_model";

class TableGamesIndex extends BaseIndexPage {
    //public model = TableGamesModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesModel;
        super.defaultShowAttrs = TableGamesModel.defaultShowAttrs;
        super.relationTree = TableGamesModel.relationTree;
    }
}

export default TableGamesIndex;