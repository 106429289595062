import {Constants} from "../../utils/constants";
import React from "react";
import {Col, Row} from "react-bootstrap";

type Props = {
    image_name?: string,
    name?: string,
    role?: string
}
export default function UserProfileView(props: Props) {

    return <Row className='d-flex'>
        <Col md={"auto"}>
            <img alt="Ava"
                 className="img-circle"
                 width={50} height={50}
                 src={(props?.image_name)
                     ? Constants.publicImageViewPath + "users/400/" + props?.image_name
                     : Constants.publicImageViewPath + "noAvatar.png"
                 }/>
        </Col>

        {/*style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth:"140px"}}*/}
        <Col style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            <span className="text-md pl-1">{props.name}</span><br/>
            <span className="text-sm pl-1">{props.role}</span>
        </Col>

    </Row>
}