/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import WikiPagesForm from "./_form";
import {WikiPagesModel} from "./_model";

class WikiPagesUpdate extends BaseUpdatePage {
    //public model = WikiPagesModel;
    //public FormComponent = WikiPagesForm;

    constructor(props: any) {
        super(props);
        super.model = WikiPagesModel;
        super.FormComponent = WikiPagesForm;
        super.relationTree = WikiPagesModel.relationTree
    }
}

export default withTranslation()(withRouter(WikiPagesUpdate));
