/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TelegrambotModulesModel: BaseModelInfo = {
    info: {
        name: "telegrambot-modules",
        label: "Telegrambot Modules",
        apiPath: "/telegrambot/api/telegrambot-modules",
        viewPath: "/telegrambot/telegrambot-modules"
    },
    attrs: {
        id: {label: "ID"},
        parent_module_id: {
            label: "Родительский модуль",
            params: {model: {info: {apiPath: "/telegrambot/api/telegrambot-modules"}}}
        },
        name: {
            label: "Базовый модуль",
            params: {model: {info: {apiPath: "/telegrambot/api/telegrambot-modules/get-base-modules"}}}
        },
        descr: {label: "Название в админке"},
        help_message: {label: "Описание для пользователя"},
        done_message: {label: "Сообщение пользователю о завершении"},
        undone_message: {label: "Сообщаем пользователю что незавершено"},
        version: {label: "Версия"},
        created_at: {label: "Дата создания"},
        //dependModules: {label: '"DependModules"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        parent: {label: '"Parent"', type: FieldType.RelationOneData, params: {name: "name", model: this}},
        //childrens: {label: '"Childrens"', type: FieldType.RelationOneData, params: {name: "name", model: null}},

        /**  /!*  name: new AttrInfo("name", "Название модуля"),*!/
         descr: new AttrInfo("descr", "Название в админке"),
         help_message: new AttrInfo("help_message", "Описание для пользователя"),
         done_message: new AttrInfo("done_message", "При завершении"),
         undone_message: new AttrInfo("undone_message", "при НЕзавершении"),
         version: new AttrInfo("version", "Версия"),
         created_at: new AttrInfo("created_at", "Дата создания"),
         */
        telegrambotModuleCommands: {
            label: '"TelegrambotModuleCommands"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
        telegrambotModuleConfigs: {
            label: '"TelegrambotModuleConfigs"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
        telegrambotModuleDatas: {
            label: '"TelegrambotModuleDatas"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
        telegrambotModuleDepends: {
            label: '"TelegrambotModuleDepends"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
        telegrambotModuleDepends0: {
            label: '"TelegrambotModuleDepends0"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
    },
    relationTree: {
        relations: {
            telegrambotModuleCommands: [],
            telegrambotModuleConfigs: []
        }
    },
    defaultShowAttrs: ["parent_module_id", "name", "descr", "help_message", "done_message",]
};


