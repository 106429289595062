/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TableGamesThemesModel} from "./_model";

class TableGamesThemesIndex extends BaseIndexPage {
    //public model = TableGamesThemesModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesThemesModel;
        super.defaultShowAttrs = TableGamesThemesModel.defaultShowAttrs;
        super.relationTree = TableGamesThemesModel.relationTree
    }
}

export default TableGamesThemesIndex;