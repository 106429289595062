/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
import {faClock, faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Constants} from "../../../utils/constants";
import TableGamesTypes from "../table-games-types";
import {TableGamesTypesModel} from "../table-games-types/_model";
import {TableGamesCoopsModel} from "../table-games-coops/_model";
import {TableGamesThemesModel} from "../table-games-themes/_model";
import {TableGamesPricesModel} from "../table-games-prices/_model";
import {TableGamesPlacesModel} from "../table-games-places/_model";

export const TableGamesModel: BaseModelInfo = {
    info: {
        name: "table-games",
        label: "Настолки",
        apiPath: "/rapi/table-games",
        viewPath: "/table-games/table-games"
    },

    attrs: {
        id: {label: "ID"},
        name: {label: "Имя"},
        image_name: {
            label: "Фото",
            type: FieldType.Image,
            params: {url: Constants.publicImageViewPath + "table_games/400/"}
        },

/*        image_name: {
            label: "Фото",
            type: FieldType.Custom,
            params: (val: any) => <img alt="avatar_only" className="img-circle" width={50} height={50}
                                       src={Constants.publicImageViewPath+ "events/" + val}/>
        },*/
        name_rus: {label: "Альт. Имя"},
        game_rules: {label: "pdf файл правил"},
        descr: {label: "Описание"},
        age: {label: "возрастные ограничения"},
        short_descr: {label: "Короткое Описание"},
        bgg_id: {label: "id игры на сайте boardgamegeek.com"},
        bgg_rate_average: {label: "Рейтинг с сайта boardgamegeek.com"},
        complexity: {label: "сложность с 0 до 4"},
        is_active: {label: "доступно в заведении"},
        players_from: {
            label: <span><FontAwesomeIcon icon={faPeopleGroup}/><span className="pl-2">min игроков</span></span>
        },
        players_to: {
            label: <span><FontAwesomeIcon icon={faPeopleGroup}/><span className="pl-2">max игроков</span></span>
        },
        duration: {label: <span><FontAwesomeIcon icon={faClock}/><span className="pl-2">длительность мин</span></span>},
        shelf_num: {label: "номер полки"},
        price_full: {label: "полная цена"},
        price_day: {label: "цена за день"},
        cnt: {label: "сумма на складе"},
        available_for_sale: {label: "доступность для продажи"},
        available_for_rent: {label: "доступность для аренды"},
        /** ↓ Work but deprecated
         //themes: {label: '"Themes"', type: FieldType.RelationMany, params: {name: "name", model: null}},
         //types: {label: '"Types"', type: FieldType.RelationMany, params: {name: "name", model: null}},
         //coops: {label: '"Coops"', type: FieldType.RelationMany, params: {name: "name", model: null}},
         //prices: {label: '"Prices"', type: FieldType.RelationMany, params: {name: "name", model: null}},
         //places: {label: '"Places"', type: FieldType.RelationMany, params: {name: "name", model: null}},
         **/
        tableGamesThemes: {
            label: '"TableGamesThemes"',
            type: FieldType.RelationMany,
            params: {name: "name", model: TableGamesThemesModel}
        },
        tableGamesTypes: {
            label: '"TableGamesTypes"',
            type: FieldType.RelationMany,
            params: {name: "name", model: TableGamesTypesModel}
        },
        tableGamesCoops: {
            label: 'Кооператив',
            type: FieldType.RelationMany,
            params: {name: "name", model: TableGamesCoopsModel}
        },
        tableGamesPrices: {
            label: '"TableGamesPrices"',
            type: FieldType.RelationMany,
            params: {name: "name", model: TableGamesPricesModel}
        },
        tableGamesPlaces: {
            label: '"TableGamesPlaces"',
            type: FieldType.RelationMany,
            params: {name: "name", model: TableGamesPlacesModel}
        },
        periods: {label: '"Periods"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        parentLinks: {label: '"ParentLinks"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        childrenLinks: {label: '"ChildrenLinks"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        childrens: {label: '"Children"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        parents: {label: '"Parents"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        bgg: {label: '"Bgg"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        tableGamesImgs: {
            label: '"TableGamesImgs"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
        tableGamesInUses: {
            label: '"TableGamesInUses"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
        tableGamesLinks: {
            label: '"TableGamesLinks"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
        /* tableGamesLinks0: {
             label: '"TableGamesLinks0"',
             type: FieldType.RelationOneData,
             params: {name: "name", model: null}
         },
         tableGamesToCoops: {
             label: '"TableGamesToCoops"',
             type: FieldType.RelationOneData,
             params: {name: "name", model: null}
         },
         tableGamesToPlaces: {
             label: '"TableGamesToPlaces"',
             type: FieldType.RelationOneData,
             params: {name: "name", model: null}
         },
         tableGamesToPrices: {
             label: '"TableGamesToPrices"',
             type: FieldType.RelationOneData,
             params: {name: "name", model: null}
         },
         tableGamesToThemes: {
             label: '"TableGamesToThemes"',
             type: FieldType.RelationOneData,
             params: {name: "name", model: null}
         },
         tableGamesToTypes: {
             label: '"TableGamesToTypes"',
             type: FieldType.RelationOneData,
             params: {name: "name", model: null}
         },*/
    },

    relationTree: {
        relations: {
            coopTypes: [],
            tableGamesCoops: [],
            tableGamesImgs: [],
            tableGamesThemes: [],
            //"table-games-types":[]
        }
    },
    defaultShowAttrs: ["image_name", "name", "name_rus", "short_descr", "tableGamesCoops"]
};


