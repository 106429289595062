/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TableGamesPricesModel} from "./_model";

class TableGamesPricesIndex extends BaseIndexPage {
    //public model = TableGamesPricesModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesPricesModel;
        super.defaultShowAttrs = TableGamesPricesModel.defaultShowAttrs;
        super.relationTree = TableGamesPricesModel.relationTree
    }
}

export default TableGamesPricesIndex;