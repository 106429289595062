/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import EventsForm from "./_form";
import {EventsModel} from "./_model";

class EventsUpdate extends BaseUpdatePage {
    //public model = EventsModel;
    //public FormComponent = EventsForm;

    constructor(props: any) {
        super(props);
        super.model = EventsModel;
        super.FormComponent = EventsForm;
        super.relationTree = EventsModel.relationTree
    }
}

export default withTranslation()(withRouter(EventsUpdate));
