/**
 * Generated by ReactYii 3.0.27 at 07.05.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TagsModel: BaseModelInfo = {
    info: {
        name: "tags",
        label: "Tags",
        apiPath: "/rapi/tags",
        viewPath: "/tags"
    },
    attrs: {
        id: {label:"ID"},
        name: {label:"Tag name"},
    wikiToTags: {label:'"WikiToTags"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
        },
    relationTree: {},
    defaultShowAttrs:["name", ]
};


