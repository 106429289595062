/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
import {TarifsModel} from "../tarifs/_model";
import {RoleGroupsModel} from "../role-groups/_model";

export const RolesModel: BaseModelInfo = {
    info: {
        name: "roles",
        label: "Roles",
        apiPath: "/rapi/roles",
        viewPath: "/Roles"
    },
    attrs: {
        id: {label: "ID"},
        name: {label: "Название"},
        descr: {label: "Описание"},
        level: {label: "Уровень"},
        roleGroup: {
            label: 'Группа',
            type: FieldType.RelationOneData,
            //params: {name: "name", model: RoleGroupsModel,}
        },
        tarif: {
            label: 'Тариф',
            type: FieldType.RelationOneData,
            //params: {name: "name", model: TarifsModel}
        },
        tarif_id: {label: "ID Тарифа", type: FieldType.RelationOneID, params: {name: "name", model: TarifsModel}},
        role_group_id: {label: "ID Группы", type: FieldType.RelationOneID, params: {name: "name", model: RoleGroupsModel}},
        //users: {label: '"Users"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },

    relationTree: {
        relation: {
            roleGroup: [],
            tarif: [],
        },
        counts: [
            "user"
        ]
    },
    defaultShowAttrs: ["name", "descr", "roleGroup", "tarif"]
};


