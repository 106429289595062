/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TelegrambotUserMessagesModel: BaseModelInfo = {
    info: {
        name: "telegrambot-user-messages",
        label: "Telegrambot user-messages",
        apiPath: "/telegrambot/api/telegrambot-user-messages",
        viewPath: "/telegrambot/telegrambot-user-messages"
    },
    attrs: {
        id: {label: "ID"},
        user_id: {label: "Пользователь"},
        is_answered: {label: "Флаг ответа"},
        type: {label: "Тип запроса"},
        user_triggers: {label: "Данные пользователя на момент запроса"},
        message: {label: "Вопрос"},
        created_at: {label: "Дата создания"},
        updated_at: {label: "Дата ответа"},
        telegrambotUserMessageAnswers: {
            label: '"TelegrambotUserMessageAnswers"',
            type: FieldType.RelationOneData,
            params: {name: "name", model: null}
        },
        users: {label: '"Users"', type: FieldType.RelationOneData, params: {name: "name", model: null}},
    },
    relationTree: {},
    defaultShowAttrs: ["user_id", "is_answered", "type", "user_triggers", "message",]
};


