import React, {useCallback, useEffect, useState} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {addWindowClass, calculateWindowSize, removeWindowClass, sleep} from '../utils/helpers';
import Footer from "./layout/Footer";
import Header from "./layout/Header";
import MainLoader from "./loaders/MainLoader";
import {observer} from "mobx-react-lite";
import {uiStore} from "../store/UiStore";
import {authStore} from "../store/AuthStore";
import MenuSidebar from "../adminLTE/components/sidebar/MenuSidebar";
import {MENU} from "./Menu";
import useWindowSize from "../utils/hooks/useWindowSize";

const Layout = observer(() => {
    const menuSidebarCollapsed = uiStore.menuSidebarCollapsed
    const [isAppLoaded, setIsAppLoaded] = useState(false);
    const navigate = useNavigate();
    const windowSize = useWindowSize();


    function handleFullscreenChange() {
        if (document.fullscreenElement) {
            document.body.style.overflow = "auto";
        } else {
            document.body.style.overflow = "";
        }
    }

    document.addEventListener("fullscreenchange", handleFullscreenChange);


    const fetchProfile = async () => {
        try {
            if (authStore.isAuthorized) {

            } else {
                navigate("/login")
                authStore.logout();
            }
            await sleep(500);
            setIsAppLoaded(true);
        } catch (error) {
            authStore.logout();
            await sleep(500);
            setIsAppLoaded(true);
        }
    };

    useEffect(() => {
        removeWindowClass('register-page');
        removeWindowClass('login-page');
        removeWindowClass('hold-transition');
        addWindowClass('sidebar-mini');

        fetchProfile()
        return () => {
            removeWindowClass('sidebar-mini');
        };
    }, []);

    useEffect(() => {
        const size = calculateWindowSize(windowSize.width);
        if (uiStore.screenSize !== size) {
            uiStore.screenSize = size
        }
    }, [windowSize]);

    useEffect(() => {
        removeWindowClass('sidebar-closed');
        removeWindowClass('sidebar-collapse');
        removeWindowClass('sidebar-open');
        if (menuSidebarCollapsed && uiStore.screenSize === 'lg') {
            addWindowClass('sidebar-collapse');
        } else if (menuSidebarCollapsed && uiStore.screenSize === 'xs') {
            addWindowClass('sidebar-open');
        } else if (!menuSidebarCollapsed && uiStore.screenSize !== 'lg') {
            addWindowClass('sidebar-closed');
            addWindowClass('sidebar-collapse');
        }
    }, [windowSize, menuSidebarCollapsed]);


    const getAppTemplate = useCallback(() => {
        if (!isAppLoaded) {
            return <MainLoader wrapperClass="preloader flex-column justify-content-center align-items-center"/>
        }
        return <>
            <Header/>

            <MenuSidebar menu={MENU} showSearch={true}/>

            <div className="content-wrapper">
                <div className="pt-1"/>
                <section className="content">
                    <Outlet/>
                </section>
            </div>
            <Footer/>
            {/* <ControlSidebar />*/}

            <div
                id="sidebar-overlay" role="presentation"
                onClick={uiStore.toggleMenuSidebarCollapsed}
                onKeyDown={() => {
                }}
            />

        </>;
    }, [isAppLoaded]);

    return <div className="wrapper">{getAppTemplate()}</div>;
});

export default Layout;