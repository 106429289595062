/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import TelegrambotMessagesForm from "./_form";
import {TelegrambotMessagesModel} from "./_model";

class TelegrambotMessagesUpdate extends BaseUpdatePage {
    //public model = TelegrambotMessagesModel;
    //public FormComponent = TelegrambotMessagesForm;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotMessagesModel;
        super.FormComponent = TelegrambotMessagesForm;
        super.relationTree = TelegrambotMessagesModel.relationTree
    }
}

export default withTranslation()(withRouter(TelegrambotMessagesUpdate));
