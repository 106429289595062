import React, {useEffect, useState} from 'react';
import SortableTree from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css';
import {getTree, setTree} from "../../../api/telegram/tree";
import {toast} from "react-toastify";
import {breadcrumbsStore} from "../../../store/BreadcrumbsStore";
import MainLoader from "../../../ui/loaders/MainLoader";
import {Button} from "react-bootstrap";
import {Translation} from "react-i18next";

export default function Tree(props: any) {
    const [treeData, setTreeData] = useState<any>([]);
    const [isDataLoaded, setDataLoaded] = useState(false);


    function renameKeys(data: any, oldField: string, newField: string) {
        return data.map((item: any) => {
            const newItem = {...item};
            newItem[newField] = newItem[oldField];
            newItem.expanded = true;
            delete newItem.oldField;
            if (newItem.children) {
                newItem.children = renameKeys(newItem.children, oldField, newField);
            }
            return newItem;
        });
    }

    const loadTree = () => {
        getTree()
            .then((resp: any) => {
                const tmp = renameKeys(resp.data, "name", "title")
                console.log(tmp);
                //  console.log(tmp);
                setTreeData(tmp)
                setDataLoaded(true)
            })
            .finally(() => setDataLoaded(true))
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed loadTree'));
    }
    useEffect(() => {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: "Дерево модулей"}
        ];
        loadTree()


    }, []);


    const save = () => {
        const tmp = renameKeys(treeData, "title", "name")
        console.log(tmp)
        setTree(tmp)
            .then((resp: any) => {
                console.log(resp);
                loadTree();
                toast.success("Saved!")
            })
            .finally(() => setDataLoaded(true))
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed'));

    }

    return (
        <div style={{height: 600}}>
            <Button onClick={save}>save</Button>
            {
                (isDataLoaded) ?
                    <SortableTree
                        rowDirection={""}
                        //nodeContentRenderer={<NodeRendererDefault {...defaultNodeRendererProps}/>}
                        treeData={treeData}
                        onChange={(treeData) => setTreeData(treeData)}
                        // onChange={treeData => this.setState({ treeData })}

                        /* <OrgTreeComponent
                             data={treeData}
                             ref={treeRef}
                             horizontal
                             renderCard={({isDragging, label, labelId, data, isPreviewCard}) => (
                                 <Card>
                                     {!isPreviewCard && <p>#{data.id}</p>}
                                     <Link to={"/telegrambot/telegrambot-modules/view/" + data.id}><h5 id={labelId}>{data.name}</h5></Link>
                                     <small>{data.descr}</small>
                                     {/!* <p className="btn btn-success"><small>{data.done_message}  </small></p>
                                     <p className="btn btn-info"><small>{data.help_message}</small></p>*!/}
                                 </Card>
                             )}*/
                    /> : <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>

            }

        </div>
    );


}