import React from "react";
import BaseViewPage from "../BaseViewPage";
import {BaseModelInfo} from "../../types/BaseModelInfo";
import {Link, Navigate} from "react-router-dom";
import {Button, Card, Col, Row} from "react-bootstrap";
import {BaseDeleteModal} from "../../dialogs/BaseDeleteModal";
import {Translation} from "react-i18next";
import DataTable from "../../data_viewers/DataTable";
import TableLoader from "../../../ui/loaders/TableLoader";

type Props = {
    modelInfo: BaseModelInfo;
    onCloseThis: () => void;
    //onRowClick: (id:number) => void;

}

export class SpaCrudView extends BaseViewPage {
    constructor(props: Props) {
        super(props);
        super.model = props.modelInfo;
        //super.defaultShowAttrs = props.modelInfo.defaultShowAttrs;
        super.relationTree = props.modelInfo.relationTree
    }

    render() {
        if (this.state.redirect) {
            this.props.onCloseThis()
            return <Navigate to={this.model.info.viewPath}/>;
        }

        return (this.state.isDataLoaded)
            ? <Card className="card-outline card-success">
                <BaseDeleteModal show={this.state.modal} onDelete={this.deleteModel} onClose={this.closeModal}/>
                <Card.Header>
                    <Row className="justify-content-between">
                        <Col><h1>{this.state.data?.name ?? "#" + this.state.data?.id}</h1></Col>
                        <Col>
                            <div className="float-right">

                                <Link className="btn btn-primary"
                                      to={this.model.info.viewPath + '/update/' + this.state.data.id}>
                                    <Translation>{(t: any) => t('crud.update')}</Translation>
                                </Link>
                                <Button variant="danger" className="ml-2"
                                        onClick={() => this.openModal(this.state.data.id)}>
                                    <Translation>{(t: any) => t('crud.delete')}</Translation>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <DataTable
                        data={this.state.data}
                        model={this.model}
                        disabledFields={this.disabledAttr}
                    />
                </Card.Body>
            </Card> : <TableLoader/>;
    }
}