import React from "react";
import {format} from "date-fns";
import {ru} from 'date-fns/locale'
import Ribbon from "../../../../crud/components/Ribbon";

interface Props {
    name: any // React.PropTypes.instanceOf(Date).isRequired,
    eventType: any
    from_dt: Date
    //part: any
}

class EventTitle extends React.Component<Props> {

    renderTime() {

        if (!this.props.from_dt) {
            return 'Не запланировано';
        }
        let date = new Date(this.props.from_dt);


        return (
            /*  dateTime={this.props.from_dt.toISOString()}*/
            <time itemProp="startDate">
                {format(date, 'dd MMMM в HH:mm', {locale: ru})}
                {/*    ${formatDate(date)} в ${formatTime(date)}*/}
            </time>
        );
    }

    render() {
        return (
            <div className="event-title-container">
                <Ribbon className="contacts-ribbon" title={this.props?.name}/>
                {/*      <Ribbon title={this.props.name}/>*/}
                <div className="event-title-date">
                    <span>{this.props?.eventType?.name}</span> · <span>{this.renderTime()}</span>
                    {/*    {this.props.type}*/}
                    {/*  {this.props.type} · {this.renderTime()}*/}
                </div>
            </div>
        );
    }
}

export default EventTitle;
