/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import TelegrambotUserMessagesForm from "./_form";
import {TelegrambotUserMessagesModel} from "./_model";


class TelegrambotUserMessagesCreate extends BaseCreatePage {
    //public model = TelegrambotUserMessagesModel;
    //public FormComponent = TelegrambotUserMessagesForm;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotUserMessagesModel;
        super.FormComponent = TelegrambotUserMessagesForm;
        super.relationTree = TelegrambotUserMessagesModel.relationTree
    }
}

export default TelegrambotUserMessagesCreate;