import React, {useEffect} from 'react';
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {KassaOnlineTable} from "./tabs/online";
import {KassaOfflineTable} from "./tabs/offline";
import {checkoutStore} from "../../api/store/CheckoutStore";
import {observer} from "mobx-react-lite";
import {CheckpointModal} from "./modals/CheckpointModal";
import {InvoicesTable} from "./tabs/invoices";
import {Translation} from "react-i18next";

export const KassaIndex = observer(() => {
    useEffect(() => {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: "Касса"}
        ];
    });

    return <Row>
        <CheckpointModal/>
        <Col>
            <Tabs defaultActiveKey="online">
                <Tab eventKey="online"
                     title={<span className="btn btn-sm btn-success">Онлайн<span
                         className="badge badge-info right">{checkoutStore.checkpoints.length}</span></span>}>
                    <KassaOnlineTable/>
                </Tab>

                <Tab eventKey="invoices" title={<span className="btn btn-sm btn-warning">Оплаты</span>}>
                    <InvoicesTable/>
                </Tab>

                <Tab eventKey="offline" title={<span className="btn btn-sm btn-secondary">История приходов</span>}>
                    <KassaOfflineTable/>
                </Tab>
            </Tabs>
        </Col>
    </Row>
})

export default KassaIndex;