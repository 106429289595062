import React, {useEffect, useState} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import CamToolbar from "./toolbar";
import {useNavigate} from "react-router-dom";
import {CamViewState} from "./types";
import {breadcrumbsStore} from "../../store/BreadcrumbsStore";
import {Translation} from "react-i18next";
//import ReactPlayer from 'react-player'
//import AxiosDigestAuth from '@mhoc/axios-digest-auth';


export default function CamIndex(props: any) {
    const username = 'zd';
    const passwd = 'ilovetea';
    const navigate = useNavigate();

    const [camViewState, setCamViewState] = useState(new CamViewState())
    const [updateInc, setUpdateInc] = useState(1)


    useEffect(() => {
        breadcrumbsStore.items = [
            {name: <Translation>{(t: any) => t('crud.home')}</Translation>, link: '/'},
            {name: "Камеры", link: ''}
        ];

        const interval = setInterval(() => {
            setUpdateInc((prevCounter) => prevCounter + 1);
        }, camViewState.refresh);

        return () => clearInterval(interval);


    }, []);
    //let camViewState = new CamViewState()

    //const base = 'http://195.91.199.99:9786';
    const base = 'https://zdbot.zdver.com:4445';
    /*
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    */


    const [values, setValues] = useState<any>([]);


    /*    const MakeARequest = async () => {
            const response = await digestAuth.request({
                headers: { Accept: "application/json" },
                method: "GET",
                url: base + "/cameras",
            }).then(r  =>{
                setValues(r.data)
                console.log(r.data)
            })
        }*/

    useEffect(() => {
        axios.get(base + "/cameras", {
            headers: {Accept: "application/json", 'Authorization': 'Basic ' + btoa(username + ':' + passwd)}
        }).then(r => {
            console.log(r)
            setValues(r.data)
        });
        // const client = new DigestFetch(username, passwd)
        /*        client.fetch(base + "/cameras",{ basic: true } ).then((res:any) =>{
                    console.log(res);
                    console.log(client);
                    console.log(res.headers);

                } )*/
        /*
                const digestAuth = new AxiosDigestAuth({
                    password: passwd,
                    username: username,
                });
                 digestAuth.request({
                    headers: { Accept: "application/json" },
                    method: "GET",
                    url: base + "/cameras",
                });
        */

        /*  const axiosDigest = new AxiosDigest(username, passwd);
          axiosDigest.get(base + "/cameras",).then(r => {
              setValues(r.data)
              console.log(r.data)
          });*/

    }, []);


    // axiosDigest.get(base + "/cameras", headers).then(r  =>{

    //
    return (
        <Container fluid={true}>
            <CamToolbar value={camViewState}/>
            <Row className={"row-cols-" + camViewState.cols}>
                {
                    values.map((item: any) => (
                        <Col>
                            <Card className="card-dark" onClick={() => navigate("/cam/" + item.uri.split("/")[2])}>
                                <Card.Header><Card.Title>{item.name}</Card.Title></Card.Header>
                                <img src={
                                    base + item.uri + "/image" +
                                    "?quality=" + camViewState.quality +
                                    "&resolution=" + camViewState.res +
                                    "&update=" + updateInc
                                }
                                     className="img-fluid"/>

                                {/* <img src={base+item.uri+"/video?quality=20&fps=10&resolution=320x240"} width="500px" height="auto"/>*/}
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </Container>
    )
}

