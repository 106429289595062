import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Col, Container} from "react-bootstrap";
import axios from "axios";
import stringify from "qs-stringify";
import {GlobalOnError, GlobalOnResponse} from "api/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Constants} from "utils/constants";
import {faUserAlt} from "@fortawesome/free-solid-svg-icons";

class emuMessage {
    fromMe: boolean;
    text: string;
    audio?: any;
    photo?: any;
    document?: any;

    constructor(fromMe: boolean, {text, audio, photo}: { text?: string, audio?: any, photo?: any }) {
        this.fromMe = fromMe;
        this.text = text ?? "";
        this.audio = audio;
        this.photo = photo;
    }

    formatText = () => this.text?.replace(new RegExp("\n", "g"), "<br>")

    renderMsg(key: number) {
        return (
            <div key={key} className={`direct-chat-msg ${this.fromMe ? "right" : "left"}`}>
                {
                    this.fromMe
                        ?
                        <FontAwesomeIcon icon={faUserAlt} className="direct-chat-img nav-icon text-right" size={"2x"}/>
                        : <img className="direct-chat-img nav-icon text-right" src="/logo.png" alt="logo"/>
                }

                <div className="direct-chat-text direct-chat-secondary">
                    <div dangerouslySetInnerHTML={{__html: this.formatText()}}/>
                    {
                        (this.photo) && <img src={this.photo} alt=""/>
                    }
                </div>
            </div>)
    }

}

export default function TelegramEmu(props: any) {
    const [inputValue, setInputValue] = useState("");
    const [moduleId, setModuleId] = useState(0);
    const [messages, setMessages] = useState<Array<emuMessage>>([]);
    const [emuUserStorage, setEmuUserStorage] = useState({});
    const [globalBtn, setGlobalBtn] = useState([]);
    const [inlineBtn, setInlineBtn] = useState([]);
    useEffect(() => sendMsg("/start"), [])

    const onSubmit = (e: any) => {
        e.preventDefault();
        sendMsg(inputValue)
        setInputValue("")
    }

    // @ts-ignore
    const scrollToBottom = () => (msgBottom?.current.scrollTo(0, msgBottom?.current?.scrollHeight))
    const msgBottom = useRef(null)

    const sendMsg = (msg: string) => {
        // setMessages([...messages, new emuMessage(true, msg)])
        scrollToBottom();
        axios.post(`${Constants.API_URL}${Constants.ApiVersion}/telegrambot/debug-callback/index`,
            stringify({
                is_callback: 0,
                text: msg,
                storage: emuUserStorage,
                module_id: moduleId,
            }))
            .then((response) => GlobalOnResponse(response, (response: any) => {
                return {
                    onSuccess: () => {
                        let respData = response.data.data;
                        console.log(respData)
                        setGlobalBtn(respData.global_keyboard_array)
                        setInlineBtn(respData.inline_keyboard_array)
                        setMessages(
                            [
                                ...messages,
                                new emuMessage(true, {text: msg}),
                                //new emuMessage({fromMe: false, text: respData.text[0].text})
                                new emuMessage(false,
                                    {
                                        text: respData.message[0].text,
                                        audio: respData.message[0].audio,
                                        photo: respData.message[0].photo
                                    }
                                ),
                            ])
                        setEmuUserStorage(respData.storage)

                    },
                    onError: () => {
                        console.log(response)
                        scrollToBottom();
                        //setMessages([...messages, new emuMessage(false, "ERR!" + response)])
                    }
                }
            }))
            .catch((error: any) => GlobalOnError(error))
        scrollToBottom();
    }

    return <Container fluid={true}>
        <Col md={6} className="offset-3">
            <Card className="direct-chat direct-chat-primary">
                <Card.Header><Card.Title>Эмулятор бота</Card.Title></Card.Header>
                <Card.Body>
                    <div className="direct-chat-messages" style={{height: " 400px"}} id="chatDiv" ref={msgBottom}>
                        {
                            messages.map((msg, index) => msg.renderMsg(index))
                        }
                        {
                            //Render inline buttons
                            inlineBtn.map((line: any, index) =>
                                <div key={index} className='d-flex justify-content-end'>
                                    {
                                        line.map((btn: any, i: number) =>
                                            <Button type="button" key={i} variant="outline-info" className="col m-1"
                                                    onClick={() => sendMsg(btn["callback_data"])}>
                                                {btn["text"]}
                                            </Button>
                                        )}
                                </div>)
                        }
                    </div>
                </Card.Body>
                <Card.Footer>
                    <form action="#" id="form" method="post" onSubmit={onSubmit}>
                        <div id="globalBtn">
                            {//Render global buttons
                                globalBtn.map((line: any, index) =>
                                    <div key={index} className='d-flex justify-content-end'>
                                        {
                                            line.map((btn: any, i: number) =>
                                                <Button key={i} type="button" variant="outline-info"
                                                        className="col m-1" onClick={() => sendMsg(btn)}>
                                                    {btn}
                                                </Button>
                                            )}
                                    </div>)
                            }
                        </div>
                        <div className="input-group" style={{paddingTop: "10px"}}>
                            <input type="text" name="message" placeholder="Сообщение..."
                                   onChange={(e) => setInputValue(e.target.value)}
                                   value={inputValue} className="form-control"/>
                            <Button id="sendBtn" variant="info" type="submit">
                                <FontAwesomeIcon icon={solid("greater-than")}/>
                            </Button>
                        </div>
                    </form>
                </Card.Footer>
            </Card>
        </Col>
    </Container>
}
