import React from 'react';
import './css/index.css';
import Chat from "./components/Chat";
import {Container} from "react-bootstrap";

export default function ChatGpt() {
    return (
        <Container>

                <aside className="sidebar">
                    <div className="chat-list">
                    </div>
                </aside>
                <div className="main">
                    <Chat />

            </div>
        </Container>

    );
}