/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import {Route} from "react-router-dom";
import EventsCreate from './create';
import EventsUpdate from './update';
import EventsView from './view';
import EventsIndex from './index';
import {EventsModel} from "./_model";

const EventsRoutes = [
<>
    <Route path={EventsModel.info.viewPath} element={<EventsIndex/>} />
    <Route path={EventsModel.info.viewPath + '/create'} element={<EventsCreate/>} />
    <Route path={EventsModel.info.viewPath + '/update/:id'} element={<EventsUpdate/>} />
    <Route path={EventsModel.info.viewPath + '/view/:id'} element={<EventsView/>} />
</>
];
export default EventsRoutes;
