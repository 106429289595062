import React, {useEffect} from 'react';
import useWebSocket, {ReadyState} from "react-use-websocket";
import {toast} from "react-toastify";
import {Constants} from "../../utils/constants";
import {checkoutStore} from "../../api/store/CheckoutStore";
import {observer} from "mobx-react-lite";
import {Button, ButtonGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug, faCheck, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import userStatus from "../../pages/kassa/models/userStatus";
import {uiStore} from "../../store/UiStore";
import {CheckpointUser} from "../../models/users/UserCheckpoint";
import UserProfileView from "../../pages/users/UserProfileView";

export const WssHeader = observer(() => {
    const {sendMessage, lastMessage, readyState} = useWebSocket(Constants.WSS_URL,
        {
            shouldReconnect: (_) => true,
            reconnectAttempts: 10,
            reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
            //reconnectInterval: 3000,
        }
    );

    useEffect(() => {
        if (lastMessage !== null) {
            const resp = JSON.parse(lastMessage?.data);
            console.log("WSS://data:", resp)
            let checkpoint: (CheckpointUser | null) = null;
            if (resp.data?.user_checkpoint?.length > 0) {
                checkpoint = resp.data?.user_checkpoint[0];
            }

            if (resp.data.user_status === userStatus.notExist) {
                toast.warning("Несуществующая карта \n " + resp.data?.user_status);
                return
            } else if (resp.data.user_status === userStatus.checkIn) {
                if (checkpoint?.user?.is_dead_soul) {
                    toast.success(<p>Записанна карта: <b>{checkpoint?.user?.barcode.slice(-3)}</b></p>);
                } else {
                    toast.success(
                        <div>
                            <UserProfileView image_name={checkpoint?.user?.image_name}
                                             name={checkpoint?.user?.name}
                                             role={checkpoint?.user?.role?.name}/>
                            <p><b>Записан успешно!<br/></b></p>
                            <b>{checkpoint?.user?.name}</b>
                        </div>
                    );
                }
                checkoutStore.currentCheckpoint = checkpoint
            } else if (resp.data.user_status === userStatus.checkOut) {

                toast.info(<div>
                    <UserProfileView image_name={checkpoint?.user?.image_name} name={checkpoint?.user?.name}
                                     role={checkpoint?.user?.role?.name}/>
                    <p><b>Уже записан!<br/></b></p>
                </div>);
                checkoutStore.currentCheckpoint = checkpoint
            }

            checkoutStore.getData()

        }
    }, [lastMessage]);


    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(() => {
        console.log("wss status: ", connectionStatus)
        //toast.info("WSS://" + connectionStatus);
    }, [connectionStatus, readyState]);

    const connectionStatusIcon = {
        [ReadyState.CONNECTING]: <FontAwesomeIcon icon={faSpinner} color="yellow"/>,
        [ReadyState.OPEN]: <FontAwesomeIcon icon={faCheck} color="green"/>,
        [ReadyState.CLOSING]: <FontAwesomeIcon icon={faSpinner} color="yellow"/>,
        [ReadyState.CLOSED]: <FontAwesomeIcon icon={faTimes} color="red"/>,
        [ReadyState.UNINSTANTIATED]: <FontAwesomeIcon icon={faBug} color="red"/>,
    }[readyState];


    return (
        (uiStore.screenSize === 'lg')
            ? <ButtonGroup aria-label="WSS status" className="btn-group-sm">
                <Button variant="dark">WSS://</Button>
                <Button variant="light">{connectionStatusIcon}</Button>
            </ButtonGroup>
            : <></>
    );
})

export default WssHeader;