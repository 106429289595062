/**
 * Generated by ReactYii 3.0.27 at 08.05.2023
 */
import {Route} from "react-router-dom";
import EventCalendarsCreate from './create';
import EventCalendarsUpdate from './update';
import EventCalendarsView from './view';
import EventCalendarsIndex from './index';
import {EventCalendarsModel} from "./_model";

const EventCalendarsRoutes = [
<>
    <Route path={EventCalendarsModel.info.viewPath} element={<EventCalendarsIndex/>} />
    <Route path={EventCalendarsModel.info.viewPath + '/create'} element={<EventCalendarsCreate/>} />
    <Route path={EventCalendarsModel.info.viewPath + '/update/:id'} element={<EventCalendarsUpdate/>} />
    <Route path={EventCalendarsModel.info.viewPath + '/view/:id'} element={<EventCalendarsView/>} />
</>
];
export default EventCalendarsRoutes;
