import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faArrowUpRightFromSquare,
    faChartSimple,
    faCheck,
    faClock,
    faHashtag,
    faMasksTheater,
    faPeopleCarryBox,
    faPeopleGroup,
    faStar,
    faTable,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";

//import StatusModal from "./StatusModal";
//import ShopListMiniCard from "./ShopListMiniCard";


const ShopView = ({data}: any) => {


    console.log(data.id)
    //const [images, setImages] = useState<any>([])
    //const [data, setData] = useState<Product | null>(null);

    function getBggRate() {
        return data?.bgg_rate_average//?.toFixed(1);
    }


    function renderDescr() {
        return {__html: data?.descr ?? ""};
    }


    return (data.id > 1) ?
        (
            <div className="card p-3">
                <div className="container-fluid">
                    <div className="wrapper row">
                        <div className="col-md-6">
                            <div className="wrapper product-view-gallery w-100">
                                {/* <ImageGallery items={images} showFullscreenButton={true}
                                                      additionalClass="w-100"/>*/}
                            </div>
                        </div>
                        <div className="details col">
                            <div className="">
                                <h3 className="product-title">{data?.name}</h3>
                                {data?.name_rus ?? <h5 className="product-title">({data?.name_rus})</h5>}
                                <p className="product-description">{data?.short_descr}</p>
                                <Row>
                                    <Col md={"auto"} sm={6} xs={6}>
                                        <FontAwesomeIcon icon={faChartSimple}/> Сложность:
                                    </Col>
                                    <Col md={"auto"} sm={6} xs={6}>
                                        {
                                            //@ts-ignore
                                            //ComplexityData[data?.complexity ?? 0]
                                        }
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={"auto"} sm={6} xs={6}>
                                        <FontAwesomeIcon icon={faClock}/> Длительность:
                                    </Col>
                                    <Col md={"auto"} sm={6} xs={6}>
                                        {
                                            // @ts-ignore
                                            //DurationData[data?.duration]
                                        }
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={"auto"} sm={6} xs={6}>
                                        <FontAwesomeIcon icon={faPeopleGroup}/> Участники:
                                    </Col>
                                    <Col md={"auto"} sm={6} xs={6}>
                                        {data?.players_from} - {data?.players_to}
                                    </Col>
                                </Row>
                                {
                                    console.log("data[\"table-games-coops\"]", data["table-games-coops"])
                                }
                                {data?.["table-games-coops"]?.[0] &&
                                    <Row>
                                        <Col md={"auto"} sm={6} xs={6}>
                                            <FontAwesomeIcon icon={faPeopleCarryBox}/> Кооператив:
                                        </Col>
                                        <Col md={"auto"} sm={6} xs={6}>
                                            {
                                                data?.["table-games-coops"].map((item: any) => (

                                                    <span>{item?.name}</span>

                                                ))
                                            }

                                        </Col>
                                    </Row>
                                }


                                {
                                    data?.["table-games-themes"]?.[0] &&
                                    <Row>
                                        <Col md={"auto"} sm={6} xs={6}>
                                            <FontAwesomeIcon icon={faMasksTheater}/> Жанр:
                                        </Col>
                                        <Col md={"auto"} sm={6} xs={6}>
                                            {/* {data.themes[0]?.name}*/}
                                            {
                                                data?.["table-games-types"].map((item: any) => (
                                                    <>
                                                        <span>{item?.name}</span><br/>
                                                    </>
                                                ))
                                            }
                                        </Col>
                                    </Row>
                                }
                                {data?.["table-games-types"]?.[0] &&
                                    <Row>
                                        <Col md={"auto"} sm={6} xs={6}>
                                            <FontAwesomeIcon icon={faHashtag}/> Тип:
                                        </Col>
                                        <Col md={"auto"} sm={6} xs={6}>
                                            {
                                                data["table-games-types"].map((item: any) => (
                                                    <>
                                                        <span>{item?.name}</span><br/>
                                                    </>
                                                ))
                                            }
                                        </Col>
                                    </Row>

                                }
                                <Row>
                                    <Col md={"auto"} sm={6} xs={6}>
                                        <FontAwesomeIcon icon={faTable}/> Полка:
                                    </Col>
                                    <Col md={"auto"} sm={6} xs={6}>№{data?.shelf_num}</Col>
                                </Row>
                                <Row>
                                    <Col md={"auto"} sm={6} xs={6}>
                                        <FontAwesomeIcon icon={faStar}/> BGG:
                                    </Col>
                                    <Col md={"auto"} sm={6} xs={6}>{getBggRate()}</Col>
                                    <a target="_blank" href={data?.bgg?.link} rel="noreferrer"> <FontAwesomeIcon
                                        icon={faArrowUpRightFromSquare}/></a>
                                </Row>
                                <br/>
                                {/* {
                                            (data?.price_day ?? 0) > 0 &&
                                            <PriceSelector product={data} onSelect={setSelectedPeriod}/>
                                        }*/}
                                <br/>
                                <span className="price">
                                            Залог: <span>{data?.price_full}руб</span>
                                        </span>

                                <div>
                                    <Row>
                                        <Col md={1}>
                                            <FontAwesomeIcon
                                                className={data?.available_for_rent ? "text-success" : "text-danger"}
                                                icon={data?.available_for_rent ? faCheck : faXmark}
                                            />
                                        </Col>
                                        <Col md={10}>
                                                <span>
                                                    {data?.available_for_rent ? "Доступно для аренды" : "Не доступно для аренды"}
                                                </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={1}>
                                            <FontAwesomeIcon
                                                className={data?.available_for_sale ? "text-success" : "text-danger"}
                                                icon={data?.available_for_sale ? faCheck : faXmark}
                                            />
                                        </Col>
                                        <Col md={10}>
                                                <span>
                                                    {data?.available_for_sale ? "Доступно для покупки" : "Не доступно для покупки"}
                                                </span>
                                        </Col>
                                    </Row>
                                </div>
                                <br/>
                                <div className="action">
                                    <button
                                        className="add-to-cart btn btn-default"
                                        disabled={!data?.available_for_rent}
                                        //onClick={() => setCheckoutModalShow(true)}
                                        type="button">
                                        Арендовать
                                    </button>
                                    <span>&nbsp;</span>
                                    {/* <button className="add-to-cart btn btn-dark"
                                                    disabled={!data.available_for_sale} type="button">Купить
                                            </button>*/}
                                </div>

                            </div>

                            <br/>

                        </div>
                    </div>

                    <Container>
                        <Tabs defaultActiveKey="descr" className="mb-3">
                            <Tab eventKey="descr" title="Описание">
                                <p className="product-description" dangerouslySetInnerHTML={renderDescr()}/>
                            </Tab>
                            <Tab eventKey="profile" title="Правила"
                                 disabled={!((data?.game_rules?.length ?? 0) > 0)}>
                                {
                                    ((data?.game_rules.length ?? 0) > 0) ?
                                        <p>
                                            {/*   <a href={data.game_rules} >Открыть</a>*/}


                                            {/*  <PDFViewer
                                                        withCredentials={true}
                                                        document={{
                                                            url: data.game_rules,
                                                        }}
                                                    />*/}

                                            {/*  <div style={{overflow:'scroll',height:600}}>
                                                    <MobilePDFReader url={data.game_rules}/>
                                                </div>*/}

                                            {/*  <object>
                                                        <embed src={data.game_rules} width="100%" height="500"/>
                                                    </object>
                                                  */}
                                            {
                                                /*
                                                <object data= type="application/pdf">
                                                    <iframe width="100%" src="https://docs.google.com/viewer?url={data.game_rules}&embedded=true"></iframe>
                                                    </object>
                                                */
                                            }
                                        </p>
                                        : <p> Нет никаких правил</p>
                                }
                            </Tab>
                            <Tab eventKey="contact" title="Продолжения/Дополнения"
                                 disabled={!((data?.parents?.length) ?? 0 > 0)}>

                            </Tab>
                        </Tabs>
                    </Container>
                </div>
            </div>
        ) : <h2>err data</h2>;

    /*    <CollapsedCard title="Вид на сайте">
            {/!* <ShopView data={this.state.model}/>*!/}
        </CollapsedCard>*/
}


export default ShopView;