import React, {useEffect, useState} from "react";

type Props = {
    value: number
}

export default function PriceWidget(props: Props) {
    const [value, setValue] = useState<number | undefined>(undefined);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (props.value !== value) {
            setValue(props.value)
            setAnimate(true)
        }
    }, [props, value]);
    useEffect(() => {
        if (animate) {
            setTimeout(() => {
                setAnimate(false)
            }, 1000);
        }
    }, [animate]);

    return <span className={`Score${animate ? " Score--bounce" : ""}`}>{value}₽</span>
}


