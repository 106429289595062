/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {FieldType} from "crud/types/FieldType";
import {BaseModelInfo} from "crud/types/BaseModelInfo";
import MomentViewer from "../../crud/field_viewers/MomentViewer";
import {getPaymentType, getPayStatus} from "../kassa/helpers";
import PriceWidget from "../kassa/widgets/PriceWidget";
import UserProfileView from "../users/UserProfileView";

export const UserInvoicesModel: BaseModelInfo = {
    info: {
        name: "user-invoices",
        label: "User Invoices",
        apiPath: "/rapi/user-invoices",
        viewPath: "/user-invoices"
    },
    attrs: {
        id: {label: "№ чека"},
        user_checkpoint_id: {label: "Событие входа"},
        user_id: {label: "ID Посетителя"},
        tarif_id: {label: "ID Тарифa"},
        //sum: {label: "Полная сумма"},
        sum: {label: "Сумма", type: FieldType.Custom, params: (val: any) => <PriceWidget value={val}/>},

        // minutes: {label: "Времени провел"},
        minutes: {
            label: "Просидел",
            type: FieldType.Custom,
            params: (val: any) => <span>{val} мин</span>
        },

        descr: {label: "Описание"},
        created_at: {label: "Дата создания", type: FieldType.MomentDate},
        tarif: {
            label: 'Тариф',
            type: FieldType.RelationOneData,
            params: {name: "name"},
            sortField: "tarif_id",
            searchField: ""
        },
        userCheckpoint: {label: '"UserCheckpoints"', type: FieldType.RelationOneData, params: {name: "name"}},
        user: {label: 'Посетитель', type: FieldType.RelationOneData, params: {name: "name"}},
        profile: {
            sourceAttr: "user",
            label: "Профиль",
            type: FieldType.Custom,
            params: (val: any) => <UserProfileView image_name={val.image_name} name={val.name} role={val?.role?.name}/>
        },
        inDate: {
            sourceAttr: "userCheckpoint",
            label: "Пришел в",
            type: FieldType.Custom,
            params: (val: any) => <MomentViewer value={val?.created_at}/>
        },
        //  <th>Посетитель</th>
        //                     <th>Пришел в</th>
        //                     <th>Провел времени</th>
        //                     <th>Тариф</th>
        //                     <th>Сумма</th>
        //                     <th>Тип оплаты</th>
        //                     <th>Статус</th>
        outDate: {
            sourceAttr: "userCheckpoint",
            label: "Дата выхода",
            type: FieldType.Custom,
            params: (val: any) => <MomentViewer value={val?.outside_at}/>
        },

        //is_payed: {label: "Флаг оплаты", type: FieldType.Bool},
        is_payed: {
            label: "Флаг оплаты",
            type: FieldType.Custom,
            params: (val: any) => <b> {getPayStatus(val)} </b>
        },

        //payment_type: {label: "Тип оплаты 0-нал, 1-credit card"},
        payment_type: {
            label: "Тип оплаты",
            type: FieldType.Custom,
            params: (val: any) => <b> {getPaymentType(val)} </b>
        },
    },

    /*  sum: new AttrInfo("sum", "Сумма", FieldType.custom, (val: any) => <PriceWidget value={val}/>),
        status: new AttrInfo(null, "Статус", FieldType.custom, (val: any) => <b>{getCheckpointStatus(val)} {getPayStatus(val)} </b>),
    */

    relationTree: {
        relation: {
            tarif: [],
            user: {
                //fields: ['username', 'id', 'barcode', 'fio', 'balance'],
                fields: ['image_name', 'id', 'barcode', 'name', 'balance'],
                relation: {'roles': []}
            },
            userCheckpoint: []
        }
    },
    defaultShowAttrs: ["id", "created_at", "profile", "tarif", "minutes", "sum", "payment_type", "is_payed"]
};


