/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {Route} from "react-router-dom";
import TableGamesCreate from './create';
import TableGamesUpdate from './update';
import TableGamesView from './view';
import TableGamesIndex from './index';
import {TableGamesModel} from "./_model";

const TableGamesRoutes = [
    <>
        <Route path={TableGamesModel.info.viewPath} element={<TableGamesIndex/>}/>
        <Route path={TableGamesModel.info.viewPath + '/create'} element={<TableGamesCreate/>}/>
        <Route path={TableGamesModel.info.viewPath + '/update/:id'} element={<TableGamesUpdate/>}/>
        <Route path={TableGamesModel.info.viewPath + '/view/:id'} element={<TableGamesView/>}/>
    </>
];
export default TableGamesRoutes;
