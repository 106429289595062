//import {ApiRequest, HttpMethod} from "../../api/api";
import {Constants} from "../../utils/constants";
//import stringify from "qs-stringify";
import axios from "axios";


export const UploadFileAPI = (modelName: string, modelId: number, files: FormData): Promise<any> => {
    console.log("UploadFileAPI")
    console.log(files)
    const url = `${Constants.API_URL}/rapi/upload/images?type=${modelName}`;
    return axios.post(url, files, {})

    //return ApiRequest<any>(HttpMethod.POST, url, stringify(params));

};
