/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import BaseCreatePage from "crud/base/BaseCreatePage";
import TableGamesPlacesForm from "./_form";
import {TableGamesPlacesModel} from "./_model";


class TableGamesPlacesCreate extends BaseCreatePage {
    //public model = TableGamesPlacesModel;
    //public FormComponent = TableGamesPlacesForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesPlacesModel;
        super.FormComponent = TableGamesPlacesForm;
        super.relationTree = TableGamesPlacesModel.relationTree
    }
}

export default TableGamesPlacesCreate;