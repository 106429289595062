/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import TableGamesThemesForm from "./_form";
import {TableGamesThemesModel} from "./_model";

class TableGamesThemesUpdate extends BaseUpdatePage {
    //public model = TableGamesThemesModel;
    //public FormComponent = TableGamesThemesForm;

    constructor(props: any) {
        super(props);
        super.model = TableGamesThemesModel;
        super.FormComponent = TableGamesThemesForm;
        super.relationTree = TableGamesThemesModel.relationTree
    }
}

export default withTranslation()(withRouter(TableGamesThemesUpdate));
