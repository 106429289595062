/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {withRouter} from "utils/hoc/withRouter";
import BaseViewPage from "crud/base/BaseViewPage";
import {TelegrambotFaqModel} from "./_model";

class TelegrambotFaqView extends BaseViewPage {
    //public model = TelegrambotFaqModel;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotFaqModel;
        super.relationTree = TelegrambotFaqModel.relationTree
    }
}

export default withRouter(TelegrambotFaqView);