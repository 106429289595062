/**
 * Generated by ReactYii 3.0.27 at 05.05.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import WikiCategoriesForm from "./_form";
import {WikiCategoriesModel} from "./_model";

class WikiCategoriesUpdate extends BaseUpdatePage {
    //public model = WikiCategoriesModel;
    //public FormComponent = WikiCategoriesForm;

    constructor(props: any) {
        super(props);
        super.model = WikiCategoriesModel;
        super.FormComponent = WikiCategoriesForm;
        super.relationTree = WikiCategoriesModel.relationTree
    }
}

export default withTranslation()(withRouter(WikiCategoriesUpdate));
