/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TelegrambotUserMessagesModel} from "./_model";

class TelegrambotUserMessagesIndex extends BaseIndexPage {
    //public model = TelegrambotUserMessagesModel;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotUserMessagesModel;
        super.defaultShowAttrs = TelegrambotUserMessagesModel.defaultShowAttrs;
        super.relationTree = TelegrambotUserMessagesModel.relationTree
    }
}

export default TelegrambotUserMessagesIndex;