import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {BaseModelInfo} from "../types/BaseModelInfo";
import {Link} from "react-router-dom";

type Props = {
    model: BaseModelInfo,
    enabledAttributes: string[],
    showSettings?: boolean,
    sortBy: any,
    searchByField: (attr: string, value: string) => any,
    toggleAttrShow: (attr: string) => any,
    onCreateClick: () => void,
}

export default function TableViewSearch(props: Props) {
    const customInput = (attr: string) => {
        let searchField = props.model?.attrs?.[attr]?.searchField;
        if (searchField) {
            return <input
                className="form-control form-control-sm" type="text"
                onChange={(value) => props.searchByField(searchField?.toString() ?? "", value.target.value)}
                placeholder={searchField}
            />;
        } else {
            return <input className="form-control form-control-sm" type="text" disabled={true} placeholder={attr}/>
        }
    }
    const defaultInput = (attr: string) =>
        <input
            className="form-control form-control-sm" type="text"
            onChange={(value) => props.searchByField(attr, value.target.value)}
            placeholder={attr}
        />



    let items = props.enabledAttributes.map((attr: string) =>
        (props.model.attrs.hasOwnProperty(attr))
            ? <th key={'head-' + attr}>
                {
                    ('searchField' in props.model.attrs[attr])
                        ? customInput(attr)
                        : defaultInput(attr)
                }

            </th> : <></>
    )
    items.push(
        <th key="searchCog">
            {
                (props.onCreateClick)
                    ? <span className="btn-outline-success btn btn-sm" onClick={props.onCreateClick}>
                                <FontAwesomeIcon icon={faPlus}/>
                             </span>
                    : <Link className="btn-outline-success btn btn-sm" to={'create'}>
                        <FontAwesomeIcon icon={faPlus}/>
                    </Link>
            }
        </th>
    )


    return <tr key="search">{items}</tr>
}