/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from 'react';
import {withRouter} from "utils/hoc/withRouter";
import BaseUpdatePage from "crud/base/BaseUpdatePage";
import {withTranslation} from "react-i18next";
import TelegrambotUserMessagesForm from "./_form";
import {TelegrambotUserMessagesModel} from "./_model";

class TelegrambotUserMessagesUpdate extends BaseUpdatePage {
    //public model = TelegrambotUserMessagesModel;
    //public FormComponent = TelegrambotUserMessagesForm;

    constructor(props: any) {
        super(props);
        super.model = TelegrambotUserMessagesModel;
        super.FormComponent = TelegrambotUserMessagesForm;
        super.relationTree = TelegrambotUserMessagesModel.relationTree
    }
}

export default withTranslation()(withRouter(TelegrambotUserMessagesUpdate));
