/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {UserInvoicesModel} from "./_model";
import ErrorWidget from "../../crud/components/ErrorWidget";
import {Card} from "react-bootstrap";
import TableIndex from "../../crud/TableIndex/TableIndex";
import MainLoader from "../../ui/loaders/MainLoader";

class UserInvoicesIndex extends BaseIndexPage {
    //public model = UserInvoicesModel;


    constructor(props: any) {
        super(props);
        super.model = UserInvoicesModel;
        super.defaultShowAttrs = UserInvoicesModel.defaultShowAttrs;
        super.relationTree = UserInvoicesModel.relationTree;
        //this.state.sortBy ="id"
        //super.onSortChange("id")
    }

    render() {
        if (this.state.hasError) {
            return <ErrorWidget/>;
        }

        return (
            <Card className="card-outline card-success">
                <Card.Body>
                    {this.state.isLoadedFirstPage ? (
                        <>
                            {/* <InfiniteScroll
                                dataLength={this.state.data.data.length}
                                next={this.loadMore}
                                //hasMore={this.state.hasMore}
                                hasMore={false}
                                loader={<MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>}
                                //endMessage={<p style={{textAlign: 'center'}}>На этом все</p>}
                                endMessage=""*/}
                            <TableIndex
                                model={this.model}
                                data={this.state.data.data}
                                enabledAttributes={this.defaultShowAttrs}
                                pagination={{data: this.state.data.pagination, callback: this.actionPagination}}
                                sortBy={this.state.sortBy}
                                onSortChange={this.onSortChange}
                                searchQuery={this.state.searchArray}
                                onSearch={this.onSearch}
                                onRelationTreeChange={this.onTreeChange}
                                onRowClick={(data) => alert(data)}
                            />
                        </>
                    ) : (
                        <MainLoader wrapperClass="flex-column justify-content-center align-items-center"/>
                    )}
                </Card.Body>
            </Card>
        );
    }
}

export default UserInvoicesIndex;