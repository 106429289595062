/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import {BaseModelInfo} from "crud/types/BaseModelInfo";

export const TelegrambotFaqModel: BaseModelInfo = {
    info: {
        name: "telegrambot-faq",
        label: "Telegrambot Faq",
        apiPath: "/telegrambot/api/telegrambot-faq",
        viewPath: "/telegrambot/telegrambot-faq"
    },
    attrs: {
        id: {label: "ID"},
        priority: {label: "Приоритет чем больше тем выше"},
        question: {label: "Вопрос"},
        answer: {label: "Ответ"},
        created_at: {label: "Дата создания"},
    },
    relationTree: {},
    defaultShowAttrs: ["priority", "question", "answer", "created_at",]
};


