//const PROD_HOST = "zdbot.zdver.com"
const API_SERVERS = ["localhost", "zdbot.zdver.com"]

class Constants {
    static ApiVersion = "/rapi"

    static publicImageViewPath = "https://zdbot.zdver.com/files/"

    static get API_URL() {
        return `https://${this.GET_API_SERVER}`;
    }

    static get API_FULL_URL() {
        return `https://${this.GET_API_SERVER}${this.ApiVersion}`;
    }


    static get WSS_URL() {
        return `wss://${this.GET_API_SERVER}:8081`;
    }

    //////////////////////////
    static get GET_API_SERVER() {
        let storage = localStorage.getItem("API_SERVER");
        return storage ?? API_SERVERS[1];
    }

    static get IS_LOCALHOST(): boolean {
        let _host = this.GET_API_SERVER
        return _host === API_SERVERS[0]
    }

    static SET_API_SERVER(url: string) {

        localStorage.setItem("API_SERVER", url);
        window.location.reload();
        //let _host = this.GET_API_SERVER
        // return _host === API_SERVERS[0]
    }

}


export {Constants, API_SERVERS};

