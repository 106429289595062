import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Col, Form} from "react-bootstrap";
import {Constants} from "../../../utils/constants";
import {UploadFileAPI} from "../../api/upload";
import {AttrInfo} from "../../types/AttrInfo";
import {toast} from "react-toastify";
import {YiiToCamel} from "../../../utils/case_transforms";

const thumbsContainer = {
    display: 'flex',
    //flexDirection: 'row',
    //flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

type Props = {
    name: string,
    /** Name of a model. */
    model: string,
    /** Value of field. */
    value: any,
    label: string | ReactElement,
    onChange: Function,
    modelId: number,
    attr?: AttrInfo,
}


function DndImage(props: Props) {
    const [files, setFiles] = useState<any>([]);

    const onDrop = useCallback(<T extends File>(acceptedFiles: T[]) => {
        const formData = new FormData();
        formData.append('files', acceptedFiles[0]);

        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

        UploadFileAPI(YiiToCamel(props.model), props.modelId, formData)
            .then((resp: any) => {
                console.log(resp.data.data[0])
                props.onChange({name: props.name, value: resp.data.data[0]})
                toast.success(resp.data.data[0])
                //{ name: "event_type_id", value: val.id  }
            })
            .catch((e) => toast.error(e))

    }, [props])

    const {getRootProps, getInputProps} = useDropzone({accept: {'image/*': []}, onDrop: onDrop});

    const thumbs = files.map((file: any) => (
        <div style={thumb} key={file?.name}>
            <div style={thumbInner}>
                <img alt="preview" src={file?.preview} style={img}
                     onLoad={() => {
                         URL.revokeObjectURL(file?.preview)
                     }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file: any) => URL.revokeObjectURL(file?.preview));
    }, [files]);

    return (
        <Form.Group controlId="formFile">
            <Form.Label>{props.label || ''}</Form.Label>
            <Form.Row className="mb-1 p-3 bg-white rounded">
                <Col md={"auto"}>
                    <img height="150px"
                         src={
                             (props.attr?.params?.url + props.value) ??
                             (Constants.publicImageViewPath) + "/" + props.model + "/" + props.value}
                    />
                </Col>
                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Col>
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                        <p className="btn btn-success">Upload</p>
                    </div>
                    <aside style={thumbsContainer}>{thumbs}</aside>
                </Col>
            </Form.Row>
        </Form.Group>
    );
}

export default DndImage;