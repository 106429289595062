import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlayCircle} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faVk} from "@fortawesome/free-brands-svg-icons";
import { Row} from "react-bootstrap";
import Places from "../places.json";

class EventPanel extends React.Component<any> {

    getFee() {
        if (this.props.fee === 0) {
            return 'Обычный тариф';
        }

        return (
            <div>
                <span>{this.props.fee}</span>
                <span className="fa fa-rub"/>
            </div>
        );
    }

    renderSocial(type: any) {
        if (this.props[type]) {
            let icon;
            switch (type) {
                case "vk":
                    icon = <FontAwesomeIcon icon={faVk}/>;
                    break;
                case "facebook":
                    icon = <FontAwesomeIcon icon={faFacebook}/>;
                    break;
                default:
                    icon = <FontAwesomeIcon icon={faPlayCircle}/>;
                    break;
            }

            return (
                <a className={`event-panel-social-item ${type}`} href={`https://${type}.com/${this.props[type]}`}>
                    {icon}
                </a>
            );
        }
    }

    render() {


        return (
            <div className="container">
                <Row className=" justify-content-between">
                    <div className="col-10">
                              <span className="event-panel-item" itemProp="name">
                            {
                                // @ts-ignore
                                Places[this.props.place]
                            }
                             </span>
                        <span className="event-panel-item">
                            {this.getFee()}

                        </span>

                    </div>
                    <div className="col-2 text-right">
                        {this.renderSocial('vk')}
                        {this.renderSocial('facebook')}
                        {
                            <div className="event-panel-social">
                                <span className="event-panel-item community">Сообщество</span>
                            </div>

                        }
                    </div>


                </Row>
                {/* <Row className="justify-content-between">
                    <Col>
                        <div className="event-panel-item" itemProp="name">
                            {
                                // @ts-ignore
                                RoomsMap[this.props.place]
                            }
                        </div>
                        <div className="event-panel-item">
                            {this.getFee()}
                        </div>
                    </Col>
                    <Col md={2} sm={1} className="ms-md-auto ">
                        {this.renderSocial('vk')}
                        {this.renderSocial('facebook')}
                        <div className="event-panel-social">
                            <span className="event-panel-item community">Сообщество</span>

                        </div>
                    </Col>
                </Row>*/}

            </div>
        );
    }
}

export default EventPanel;
