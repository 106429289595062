/**
 * Generated by ReactYii 3.0.27 at 24.04.2023
 */
import React from "react";
import BaseIndexPage from "crud/base/BaseIndexPage";
import {TableGamesImgsModel} from "./_model";

class TableGamesImgsIndex extends BaseIndexPage {
    //public model = TableGamesImgsModel;

    constructor(props: any) {
        super(props);
        super.model = TableGamesImgsModel;
        super.defaultShowAttrs = TableGamesImgsModel.defaultShowAttrs;
        super.relationTree = TableGamesImgsModel.relationTree
    }
}

export default TableGamesImgsIndex;