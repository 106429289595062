import React, {useEffect, useState} from 'react'
import {ApiRequest, HttpMethod} from "api/api";
import {InputGroup} from "react-bootstrap";
import {toast} from "react-toastify";
import CreatableSelect from "react-select/creatable";
import stringify from "qs-stringify";
import {MultiValue} from "react-select";

type Tag = {
    id:number
    name: string,
    label?: string, //hack for formatOptionLabel
};

type Props = {
    name: string,
    model: string,
    label: boolean | string,
    class?: string,
    value: any,
    onChange: Function,
    required?: boolean,
    placeholder?: string,
};

export default function TagsDropDown(props: Props) {
    const [isDataLoaded, setDataLoaded] = useState(false);

    const [relationData, setRelationData] = useState<any>([]);
    const [selected, setSelected] = useState<MultiValue<Tag>>([]);

    useEffect(() => getTags(""), []);

    /* useEffect(() => {
        Array.isArray(relationData) && setSelected(relationData.find((v: any) => v.id === props.value));
    }, [relationData, props]);
    */

    const getTags = (q?: string) => {
        ApiRequest<any>(HttpMethod.GET, "/rapi/tags?search=name%3D" + q)
            .then((resp: any) => {
                setRelationData(resp?.data ?? []);
                setDataLoaded(true)
            })
            .catch((error) => toast.error(error?.response?.data?.message || 'Failed get tags'));
    }

    const createTag = (tag: string) => {
        setDataLoaded(false)
        ApiRequest<any>(HttpMethod.POST, "/rapi/tags/create", stringify({name: tag}))
            .then((resp: any) => {
                console.log(resp.data)
                //setRelationData(resp?.data ?? []);
                setDataLoaded(true)
                setRelationData([...relationData,  resp?.data])
                toast.success("Создан новый тег #" + resp?.data.id + " "+resp?.data.name);
                setSelected([...selected,resp?.data])
            })
            .catch((error) => {
                console.log(error)
                setDataLoaded(true)
                toast.error(error?.response?.data?.message || 'Failed create tags')
            })
    }

    return (
        <InputGroup className={'form-group field-' + props.model + '-' + props.name + ' ' + props.class}>
            <InputGroup.Prepend>
                <InputGroup.Text className={'control-label'}>{props.label}</InputGroup.Text>
            </InputGroup.Prepend>
            <CreatableSelect
                isClearable
                //getOptionLabel={(v: any) => v.name}
                getOptionValue={(v: any) => v.id}
                formatOptionLabel={(option) => (option?.name) ? (option?.name) : (option?.label)}
                options={relationData}
                //noOptionsMessage={() => "null"}
                value={selected}
                onInputChange={getTags}
                placeholder={props.placeholder ?? "Выберите " + props.label}
                name={props.name}
                isLoading={!isDataLoaded}
                isSearchable={true}
                isMulti={true}
                styles={{
                    container: (baseStyles, _) => ({...baseStyles, padding: '0',}),
                    control: (baseStyles, _) => ({...baseStyles, border: "none", minHeight: "9"}),
                }}
                classNames={{
                    container: () => "form-control",
                    //control: (state) => "react-select-container form-control",
                }}
                onChange={(v) =>{
                    //console.log(v)
                    setSelected(v)
                    props.onChange(v)
                }}
                required={props.required}
                createOptionPosition={"first"}
                formatCreateLabel={(inputValue) => `Создать новый тег: "${inputValue}"`}
                onCreateOption={createTag}
                // isValidNewOption={()=>true}
                //className={(validated !== '' ? (validated === true) ? 'is-valid' : 'is-invalid' : '')}
            />
        </InputGroup>
    );
}